import React from 'react';
import { Link } from 'react-router-dom';
import ShareButton from '../../../components/ShareButton/ShareButton';

import classes from './NoticiaSimple.module.scss';

const NoticiaSimple = props => {
    const getTextoResumido = (texto, largoMaximo) => {
        if (texto === undefined) return "";
        if (texto.length <= largoMaximo) {
            return texto;
        }
        else {
            let nuevoTexto = texto.substring(0, largoMaximo);
            nuevoTexto = nuevoTexto.substring(0, nuevoTexto.lastIndexOf(" "));
            nuevoTexto = `${nuevoTexto}...`
            return nuevoTexto;
        }
    }

    const getFechaFormateada = fechaString => {
        let fecha = new Date(fechaString);
        let fechaFormateada = fecha.getDate() < 10 ? `0${fecha.getDate()}/` : `${fecha.getDate()}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getMonth() < 9 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getFullYear()} - `;
        fechaFormateada = `${fechaFormateada}${fecha.getHours() < 10 ? `0${fecha.getHours()}` : fecha.getHours()}:`;
        fechaFormateada = `${fechaFormateada}${fecha.getMinutes() < 10 ? `0${fecha.getMinutes()}` : fecha.getMinutes()}h`;
        return fechaFormateada;
    }

    let estiloFecha = {
        marginLeft: "-10px",
        color: "#b7b7b7",
        fontSize: "1.4em"
    }

    return (
        <div className={classes.NoticiaSmall}>
            <div className={classes.NoticiaSmallCard}>
                <ShareButton message={props.titular} url={window.location.origin + '/noticia?id=' + props.idNoticia} className={classes.ShareButtonC} ></ShareButton>
                <Link

                    to={`/noticia?id=${props.idNoticia}`}
                    categoria="ultimas-noticias"
                    etiqueta={props.titular}
                    accion="click"
                >
                    <p className={classes.Titular}>{getTextoResumido(props.titular, 40)}</p>
                    <p className={classes.Subtitulo}>{getTextoResumido(props.subtitulo, 60)}</p>
                    <div className={classes.Actions} style={estiloFecha}>
                        <small style={{ fontStyle: "italic" }}>
                            {getFechaFormateada(props.fechaCreacion)}
                        </small>
                        <div>{props.categoria}</div>
                    </div>
                </Link>
            </div>

        </div>
    )
};

export default NoticiaSimple;