import { FETCH_DIRECCIONES } from "../actions/direcciones"

const initState = {
    direcciones_municipales: []
}

export default (state= initState, action) => {
    switch ( action.type){
        case FETCH_DIRECCIONES: 
            return {
                direcciones_municipales: action.direciones_municipales
            }
        default:
            return state
    }
}