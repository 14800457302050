import React from 'react';
import { useSelector } from 'react-redux';

import classes from './RedesSociales.module.scss';
import RedSocialIcon from './RedSocialIcon/RedSocialIcon';



const RedesSociales = props => {
    const socialList = useSelector(state => state.principal.socialLink);

    return (
        <div className={classes.RedesSociales}>
            { socialList ? socialList.map(social => <RedSocialIcon redSocial={social.red_social} key={social.id} link={social.link} />) : null}
        </div>
    )
};

export default RedesSociales;