import React from 'react';
import ImageLoader from '../ImageLoader/ImageLoader';
import { Paragraph100 } from '../Paragraph/Paragraph';
import PropTypes from 'prop-types';

import classes from './UserPresentation.module.scss';
import { Title300 } from '../Titles/Titles';

const UserPresentation = props => {
    return (
        <div className={classes.UserPresentation}>
            <ImageLoader ratioX={1} ratioY={1} imagenPrincipal={props.imagen} className={classes.CircleImage} />
            <Title300 className={classes.Nombre}>{props.nombre}</Title300>
            {props.cargo? <Paragraph100 className={classes.Cargo}>{props.cargo}</Paragraph100>: null }
        </div>
    )
};

UserPresentation.propTypes = {
    imagen: PropTypes.string.isRequired,
    nombre: PropTypes.string.isRequired,
    cargo: PropTypes.string.isRequired
}



export default UserPresentation;