import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import classes from './ListaLinkDeInteres.module.scss';
import { useSelector } from 'react-redux';

import { TitlePrincipal } from './../../../components/Titles/Titles';

import LinkDeInteres from './LinkDeInteres/LinkDeInteres';
import Button from '../../../components/Button/Button';
import useViewportSize from '../../../hooks/useViewportSize/useViewportSize';


const ListLinkDeInteres = () => {

    const linkContainer = useRef();
    const [isMobile, setIsMobile] = useState(true);
    const [status, setStatus] = useState(false);
    const [linkContainerStyle, setLinkContainerStyle] = useState();

    const linksInteres = useSelector(state => state.principal.linkInteres);
    const texts = useSelector(state => state.principal.texts);

    let links = null;
    if (linksInteres) {
        links = linksInteres.map(link => <LinkDeInteres
            key={link.id}
            title={link.title}
            label={link.label}
            iconName={link.iconName}
            link={link.link}
        />)
    }

    const screenSize = useViewportSize()

    useMemo(() => {
        switch (screenSize) {
            case 'xs':
                console.log('xs')
                setIsMobile(true);
                break;
            default:
                setIsMobile(false);
        }
    }, [screenSize]);

    useLayoutEffect(() => {
        let style = {}
        if (linkContainer.current && linkContainer.current.firstChild) {
            if (status) {
                style = { ...style, height: `${linkContainer.current.scrollHeight < 65 ? 65 : linkContainer.current.scrollHeight + 10}px` }
            } else {
                console.dir(linkContainer.current.firstChild && linkContainer.current.firstChild.clientHeight);
                style = { ...style, height: `${linkContainer.current.firstChild && linkContainer.current.firstChild.clientHeight}px` }
            }
            setLinkContainerStyle(style);
        }
    }, [linkContainer.current, status, linksInteres])

    return (
        <div>
            <div className={classes.LinkInteresContainer}>
                <TitlePrincipal>{texts && texts.tituloLinkInteres ? texts.tituloLinkInteres : 'Link de interés'}</TitlePrincipal>
            </div>
            <div ref={linkContainer} className={classes.ListaLinkDeInteres} style={isMobile ? linkContainerStyle : null}>
                {links}
            </div>
            {isMobile ? <Button onClick={() => setStatus(!status)} >Ver más</Button> : null}

        </div>
    )
};

export default ListLinkDeInteres;