import Axios from './../../axios/strapi';

export const SHOW_INFORMATION_BANNER = 'SHOW_INFORMATION_BANNER'

export const fetchInformationBanner = () => {
    return async dispatch => {
        try {
            const banner = await Axios.get('banner-information');
            if (banner.data.estado) {
                dispatch({
                    type: SHOW_INFORMATION_BANNER,
                    informacion: banner.data.informacion,
                    link: banner.data.link
                })
            }

        } catch {

        }
    }
}