import React, { useEffect, useState } from 'react';
import ClockC from 'react-live-clock';

import classes from './Clock.module.scss';


const Clock = props => {
    const [value, setValue] = useState(new Date());
 
  // useEffect(() => {
    // const interval = setInterval(
    //   () => setValue(new Date()),
    //   1000
    // );
 
  //   return () => {
  //     clearInterval(interval);
  //   }
  // }, []);
  return (
    <div className={classes.Clock}>
        <p>Hora:</p>
        <ClockC 
            className={classes.Hora}
            format={'HH:mm'}
            // date={new Date(2020, 9, 12, 20, 20, 59)}
            // style={{fontSize: '24px'}}
            timezone={'America/Santiago'}
            ticking={true}
            />
        <p>hrs.</p>
    </div>
  )
};

export default Clock;