import React, { useState, useEffect } from 'react';
import classes from './Accessibility.module.scss';
import { useDispatch, useSelector } from 'react-redux';


import * as globalStyleActions from '../../store/actions/globalStyles';
import * as mainActions from '../../store/actions/prueba';
import { Icon } from '@material-ui/core';
import { Paragraph200 } from '../Paragraph/Paragraph';
import { useScroll } from '../../hooks/useScroll/useScroll';

const AccessibilyCard = props => {

    const dispatch = useDispatch();




    const aumentarButtonHandler = () => {
        dispatch(globalStyleActions.agrandarFuente())
    }
    const disminuirButtonHandler = () => {
        dispatch(globalStyleActions.reducirFuente())
    }

    const destacarLink = () => {
        dispatch(globalStyleActions.destacarLinks())
    }

    return (
        <div className={classes.Card}>
            <div onClick={aumentarButtonHandler} className={classes.Button}>
                <Icon>
                    add
                </Icon>
                <span>Aumentar</span>
            </div>
            <div onClick={disminuirButtonHandler} className={classes.Button}>
                <Icon>
                    remove
                </Icon>
                <span>Disminuir</span>
            </div>
            <div onClick={destacarLink} className={classes.Button}>
                <Icon>
                    link
                </Icon>
                <span>Destacar Link</span>
            </div>
        </div>
    )
}

const ContactCard = props => {
    const contactoEmergencia = useSelector(state => state.principal.contactoEmergencia);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!contactoEmergencia) {
            dispatch(mainActions.fetchContactoEmergencia())
        }

    }, [dispatch])

    return (
        contactoEmergencia ? <div className={classes.Card}>
            <a
                href={`mailto:${contactoEmergencia.mail}`}
                target="_blank"
                rel="noopener noreferrer"
                categoria="tarjeta-de-contacto"
                etiqueta="mail">
                <Paragraph200 className={classes.Text}>
                    Denuncias al:
                </Paragraph200>
                <Paragraph200>
                    denuncias@municipalidaddecartagena.cl
                </Paragraph200>
            </a>
            <a
                href={`https://api.whatsapp.com/send?phone=${contactoEmergencia.whatsapp.replace(/ /g, '')}`}
                target="_blank"
                rel="noopener noreferrer"
                categoria="tarjeta-de-contacto"
                etiqueta="whatsapp">
                <Paragraph200 className={classes.Text}>
                    Whatsapp:
                </Paragraph200>
                <Paragraph200>
                    {contactoEmergencia.whatsapp}
                </Paragraph200>
            </a>
        </div> : null
    )
}

const Accesibility = props => {
    const [accessibilityCardHandler, setAccessibilityCardHandler] = useState(false);
    const [contactHandler, setContactHandler] = useState(false);

    const scroll = useScroll();
    const { showInformationBanner } = useSelector(state => state.informationBanner)

    const style = scroll.scrollY < 100 && showInformationBanner ? { top: '250px' } : {}


    const toggleAccecibilityHandler = () => {
        setAccessibilityCardHandler(!accessibilityCardHandler);

        if (contactHandler) {
            setContactHandler(false);
        }
    }

    const toggleContactHandler = () => {
        if (contactHandler) {
            setContactHandler(false);
        } else {
            setContactHandler(true);
        }

        console.log(contactHandler);

        if (accessibilityCardHandler) {
            setAccessibilityCardHandler(false);
        }
    }

    const accessClass = [classes.Link, accessibilityCardHandler ? classes.Selected : null]
    const denunciasClass = [classes.Link, contactHandler ? classes.Selected : null]

    return (
        <div className={classes.Accessibility} style={style}>
            {/* <button onClick={aumentarButtonHandler}>Aumentar</button>
            <button onClick={disminuirButtonHandler}>Disminuir</button> */}
            <Icon className={accessClass.join(' ')} onClick={toggleAccecibilityHandler}>
                accessible
            </Icon>
            <Icon className={denunciasClass.join(' ')} onClick={toggleContactHandler}>
                report
            </Icon>

            {contactHandler ? <ContactCard></ContactCard> : null}
            {accessibilityCardHandler ? <AccessibilyCard></AccessibilyCard> : null}

        </div>
    )
};

export default Accesibility;