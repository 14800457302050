import React from 'react';
import { NavLink } from 'react-router-dom';
import { Title400 } from '../../../Titles/Titles';

import classes from './LinkMenu.module.scss';

const LinkMenu = props => {
    let enlace = null;
    if (props.link.match(/(http|https)/)) {
        enlace = (
            <a href={props.link} target="_blank" rel="noopener noreferrer" categoria="menu" etiqueta={props.label}>
                <Title400>{props.label}</Title400>
            </a>
        )
    } else {
        enlace = (
            <NavLink to={props.link} onClick={props.closeHandler} categoria="menu" etiqueta={props.label}>
                <Title400>{props.label}</Title400>
            </NavLink>
        )
    }

    return (
        <li>
            {enlace}
        </li>
    )
};

export default LinkMenu;