import React, { useState, useEffect, useCallback, useRef } from 'react';
import classes from './Carousel.module.scss';
import Hammer from 'react-hammerjs';

import Axios from './../../axios/strapi';

import bottom from './../../assets/images/SVG/Asset.svg';
import ImageLoader from '../ImageLoader/ImageLoader';
import { BASE_URL } from './../../constants';
import Clock from '../Clock/Clock';
import LogoMunicipalidadHorizontal from './../../assets/images/SVG/LOGO_MUNICICIPALIDAD_2020_HORIZONTAL.svg'




const Carousel = props => {
    const [imgCounter, setImageCounter] = useState(3);
    const [carouselItems, setCarouselItems] = useState([]);
    const [index, setIndex] = useState(0);
    const [style, setStyle] = useState({
        transform: `translateX(-0%)`
    });

    const [controlOn, setControlOn] = useState(false);

    useEffect(() => {
        Axios.get('carruseles-publicos').then(data => data.data).then(carouselItems => {
            setImageCounter(carouselItems.length);
            setCarouselItems(carouselItems);
        });
    }, []);

    const positionLoopHandler = useCallback(() => {
        if (imgCounter - 1 > index) {
            setIndex(index + 1);
            setStyle({
                transform: `translateX(-${100 * (index + 1)}%)`
            })
        }
        else {
            setIndex(0);
            setStyle({
                transform: `translateX(-${100 * (0)}%)`
            })
        }
    }, [imgCounter, setIndex, setStyle, index])

    const positionNextHandler = () => {
        if (imgCounter - 1 > index) {
            setIndex(index + 1);
            setStyle({
                transform: `translateX(-${100 * (index + 1)}%)`
            })
        }
        else {
            setStyle({
                transform: `translateX(-${100 * (index)}%)`
            })
        }
    }

    const positionPrevHandler = () => {
        if (index > 0) {
            setIndex(index - 1);
            setStyle({
                transform: `translateX(-${100 * (index - 1)}%)`
            })
        }
        else {
            setStyle({
                transform: `translateX(-${100 * (index)}%)`
            })
        }
    }

    const positionNumer = position => {
        let n = position + 1;
        if (n < 10) {
            return `0${n}`
        }
        return n;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            refImageContainer.current.ontransitionend = function (event) {
                refImageContainer.current.style.transition = null
            }
            refImageContainer.current.style.transition = 'all .5s';
            positionLoopHandler();
        }, 5000);
        return () => clearInterval(interval);

    }, [positionLoopHandler])

    const goToPosition = (index) => {
        setIndex(index);
        setStyle({
            transform: `translateX(-${100 * (index)}%)`
        })
    }

    const refContainer = useRef(null);
    const refImageContainer = useRef(null);



    return (
        <div ref={refContainer} className={classes.Carousel} >

            <div className={classes.Clock}>
                <Clock></Clock>
            </div>



            <div ref={refImageContainer} className={classes.ImageContainer} style={style}>

                {
                    carouselItems.map(({ titulo_carrusel }, i) =>
                        <Hammer key={i} onPanEnd={ev => {
                            switch (ev.additionalEvent) {
                                case 'panup':
                                    setControlOn(true)
                                    break;
                                case 'pandown':
                                    setControlOn(true)
                                    break;
                                case 'panleft':
                                    refImageContainer.current.ontransitionend = function (event) {
                                        refImageContainer.current.style.transition = null
                                    }
                                    refImageContainer.current.style.transition = 'all .5s';

                                    positionNextHandler();
                                    break;

                                case 'panright':
                                    refImageContainer.current.ontransitionend = function (event) {
                                        refImageContainer.current.style.transition = null
                                    }
                                    refImageContainer.current.style.transition = 'all .5s';
                                    positionPrevHandler();
                                    break;

                                default:
                                    setStyle({
                                        transform: `translateX(-${100 * (index)}%)`
                                    })

                            }


                        }}
                            onPan={e => {


                                if (e.additionalEvent === 'panup' || e.additionalEvent === 'pandown') {
                                    setControlOn(false);
                                }

                                if ((e.additionalEvent === 'panleft' || e.additionalEvent === 'panright') && controlOn) {
                                    const carouselWidth = refContainer.current.clientWidth;
                                    setStyle({ transform: `translateX(${carouselWidth * -index + e.deltaX}px)` })
                                }
                            }}
                        >
                            <div
                                key={titulo_carrusel.id}
                                className={classes.Image}>
                                <ImageLoader className={classes.Image} imagenPrincipal={BASE_URL + titulo_carrusel.imagen_principal.url} />
                                {titulo_carrusel.Etiqueta && titulo_carrusel.Etiqueta.status ? <p className={classes.TextLabel}>{titulo_carrusel.Etiqueta.texto}</p> : null}
                            </div>

                        </Hammer>
                    )
                }

            </div>
            <div className={classes.CarouselBottomImage} style={{
                background: `url(${bottom})`, 'backgroundRepeat': 'no-repeat',
                'backgroundSize': 'cover'
            }} />

            <div className={classes.CarouselPosition}>
                {
                    carouselItems.map(({ titulo_carrusel, id }, i) =>
                        <span key={id} className={index === i ? classes.selected : null} onClick={goToPosition.bind(this, i)}>{positionNumer(i)}</span>
                    )
                }
            </div>

            <img src={LogoMunicipalidadHorizontal} alt="Logo-municipalidad" className={classes.LogoImage} >
            </img>






        </div>
    )
};

export default Carousel;