import Axios from "./../../axios/strapi";

export const ACTIVAR_VISTA_BUSCADOR = 'ACTIVAR_VISTA_BUSCADOR';
export const DESACTIVAR_VISTA_BUSCADOR = 'DESACTIVAR_VISTA_BUSCADOR';
export const FETCH_BUSCAR_NOTICIAS = 'FETCH_BUSCAR_NOTICIAS';
export const LIMPIAR_BUSCADOR = 'LIMPIAR_BUSCADOR';
export const IR_PAGINA = 'IR_PAGINA';


const NOTICIAS_POR_VISTA = 5;

export const activarVistaBuscador = () => {
    return {
        type: ACTIVAR_VISTA_BUSCADOR
    }
}
export const desactivarVistaBuscador = () => {
    return {
        type: DESACTIVAR_VISTA_BUSCADOR
    }
}

export const fetch_buscador_noticias = (text) => {
    return async dispatch => {
        try {

            const noticiasEncontradas = await Axios.get('noticias/count', {
                params: {
                    Titular_contains: text,
                }
            })
            const noticias = await Axios.get('noticias', {
                params: {
                    Titular_contains: text,
                    _limit: NOTICIAS_POR_VISTA
                }
            })

            dispatch({
                type: FETCH_BUSCAR_NOTICIAS,
                paginas: Math.ceil(noticiasEncontradas.data / NOTICIAS_POR_VISTA),
                coincidencias: noticiasEncontradas.data,
                paginaActual: 0,
                busqueda: text,
                noticias: noticias.data
            })

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

export const irPagina = (numero, busqueda) => {
    return async dispatch => {
        try {

            const noticias = await Axios.get('noticias', {
                params: {
                    Titular_contains: busqueda,
                    _start: numero * NOTICIAS_POR_VISTA,
                    _limit: NOTICIAS_POR_VISTA
                }
            })

            dispatch({
                type: IR_PAGINA,
                paginaActual: numero,
                noticias: noticias.data
            })

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

export const limpiarBuscador = () => {
    return {
        type: LIMPIAR_BUSCADOR
    }
}