import { FETCH_CARTAGENA } from "../actions/cartagena";

const initState = null;

export default (state= initState, action) => {
    switch(action.type){
        case FETCH_CARTAGENA:
            return {
                galeria: action.galeria,
                imagenMapa: action.imagenMapa,
                imagenPrincipal: action.imagenPrincipal,
                primeraEntrada: action.primeraEntrada,
                segundaEntrada: action.segundaEntrada,
            }
        default: 
            return state;
    }
}