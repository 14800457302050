import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { Provider } from 'react-redux';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk'
import { BrowserRouter } from 'react-router-dom';
import { composeWithDevTools } from 'redux-devtools-extension';

import Layout from './Pages/Layout';

import ListenerReducer from './store/reducers/listener';
import GlobalStylesReducer from './store/reducers/globalStyles';
import LinkGobiernoReducer from './store/reducers/linkGobierno';
import LinkInteresReducer from './store/reducers/linkInteres';
import RevistaReducer from './store/reducers/revistas';
import VideoModalReducer from './store/reducers/videoModal';
import MainReducer from './store/reducers/main';
import CartagenaReducer from './store/reducers/cartagena';
import SocialReducer from './store/reducers/social';
import Principal from './store/reducers/prueba';
import menuReducer from './store/reducers/menu';
import direccionesReducer from './store/reducers/direcciones';
import consejoMunicipalReducer from './store/reducers/consejoMunicipal';
import consejoSociedadCivilReducer from './store/reducers/consejoSociedadCivil';
import organigramaMunicipalReducer from './store/reducers/organigramaMunicipal';
import noticiasReducer from './store/reducers/noticias';
import documentosInteresReducer from './store/reducers/documentosInteres';
import footerReducer from './store/reducers/footer';
import buscadorReucer from './store/reducers/buscador';
import informationBannerReducer from './store/reducers/InformationBanner';
import modalReducer from './store/reducers/modal';

import { useDispatch } from 'react-redux';
import ListenerActions from './store/actions/listener';


const rootReducer = combineReducers({
  listeners: ListenerReducer,
  globalStyles: GlobalStylesReducer,
  // linkGobierno: LinkGobiernoReducer,
  linkInteres: LinkInteresReducer,
  revista: RevistaReducer,
  videoModal: VideoModalReducer,
  // main: MainReducer,
  cartagena: CartagenaReducer,
  social: SocialReducer,
  principal: Principal,
  menu: menuReducer,
  direcciones: direccionesReducer,
  consejoMunicipal: consejoMunicipalReducer,
  sociedadCivil: consejoSociedadCivilReducer,
  organigramaMunicipal: organigramaMunicipalReducer,
  noticias: noticiasReducer,
  documentosInteres: documentosInteresReducer,
  footer: footerReducer,
  buscador: buscadorReucer,
  informationBanner: informationBannerReducer,
  modal: modalReducer
})

const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk)));

function App() {
  useEffect(() => {
    const interval = setInterval(() => {


      const links = document.getElementsByTagName("a");
      for (let link of links) {
        if (!link.getAttribute("tagged")) {
          link.setAttribute("tagged", true);
          link.onclick = function () {

            let objetoTag = {
              categoria: "link",
              accion: "click",
              etiqueta: this.href,
              event: "click"
            };
            if (link.getAttribute("categoria"))
              objetoTag = {
                ...objetoTag,
                categoria: link.getAttribute("categoria")
              };
            if (link.getAttribute("accion"))
              objetoTag = {
                ...objetoTag,
                accion: link.getAttribute("accion")
              };
            if (link.getAttribute("etiqueta"))
              objetoTag = {
                ...objetoTag,
                etiqueta: link.getAttribute("etiqueta")
              };
            window.dataLayer.push({
              ...objetoTag,
            }
            );
          }
        }
      };


    }, 100);
    return () => clearInterval(interval);
  }, []);

  const [tituloSitio, setTituloSitio] = useState("Municipalidad de Cartagena");

  window.setTituloSitio = setTituloSitio;

  useEffect(() => {
    document.title = tituloSitio;
    setTimeout(() => {
      window.dataLayer.push({
        event: "pageView",
        titulo: document.title
      });
    }, 1000);
  }, [tituloSitio]);

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div className="App">

          <Layout></Layout>

        </div>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
