import { FETCH_CONSEJO } from "../actions/consejoMunicipal";

const initState = {
    presentacion: '',
    consejeros: [],
    titulo: ''
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_CONSEJO:
            return {
                presentacion: action.presentacion,
                consejeros: action.consejeros,
                titulo: action.titulo
            }

        default:
            return state;
    }
} 