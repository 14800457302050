import { ACTIVAR_VISTA_BUSCADOR, DESACTIVAR_VISTA_BUSCADOR, FETCH_BUSCAR_NOTICIAS, IR_PAGINA, LIMPIAR_BUSCADOR } from "../actions/buscador"

const initState = {
    activado: false,
    coincidencias: 0,
    paginas: 0,
    paginaActual: 0,
    noticias: [],
    busqueda: null
}

export default (state = initState, action) => {
    switch (action.type) {
        case ACTIVAR_VISTA_BUSCADOR:
            return {
                ...state,
                activado: true,
            }
        case DESACTIVAR_VISTA_BUSCADOR:
            return {
                ...state,
                activado: false,
            }
        case FETCH_BUSCAR_NOTICIAS:
            return {
                ...state,
                noticias: action.noticias,
                busqueda: action.busqueda,
                coincidencias: action.coincidencias,
                paginas: action.paginas,
                paginaActual: action.paginaActual
            }
        case LIMPIAR_BUSCADOR:
            return {
                ...state,
                noticias: [],
                coincidencias: 0,
                paginas: 0,
                paginaActual: 0,
                busqueda: null
            }
        case IR_PAGINA:
            return {
                ...state,
                paginaActual: action.paginaActual,
                noticias: action.noticias,
            }
        default:
            return state
    }
}