import { FETCH_DATA, FETCH_MAIN_TEXTS, FETCH_CONTACTO_EMERGENCIA } from "../actions/prueba"

const initState = {
    articulos: null,
    places: null,
    linksGobierno: null,
    socialLink: null,
    linkInteres: null,
    tarjetas: null,
    noticiasMasVistas: null,
    contactoEmergencia: null,
    texts: null
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_DATA:
            return {
                ...state,
                articulos: action.revista,
                places: action.places,
                linksGobierno: action.linksGobierno,
                socialLink: action.socialLink,
                linkInteres: action.linkInteres,
                tarjetas: action.tarjeta,
                noticiasMasVistas: action.noticiasMasVistas,
                contactoEmergencia: action.contactoEmergencia,
            }
        case FETCH_MAIN_TEXTS:
            return {
                ...state,
                texts: action.texts
            }
        case FETCH_CONTACTO_EMERGENCIA:
            return {
                ...state,
                contactoEmergencia: action.contactoEmergencia,
            }
        default:
            return state
    }
}