import { FETCH_DIRECCION, FETCH_FOOTER_CONTACTOS, FETCH_FOOTER_MINISTERIOS, FETCH_REDES_SOCIALES } from "../actions/footer";

const initState = {
    ministerios: [],
    contacto: [],
    social: [],
    direccion: null
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_FOOTER_CONTACTOS:
            return {
                ...state,
                contacto: action.contactos
            }
        case FETCH_FOOTER_MINISTERIOS:
            return {
                ...state,
                ministerios: action.ministerios
            }
        case FETCH_REDES_SOCIALES:
            return {
                ...state,
                social: action.social
            }
        case FETCH_DIRECCION:
            return {
                ...state,
                direccion: action.direccion
            }
        default:
            return state;
    }
}