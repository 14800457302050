import React from 'react';
import { NavLink } from 'react-router-dom';
import { Title400 } from '../../Titles/Titles';

import classes from './GroupMenu.module.scss';
import LinkMenu from './LinkMenu/LinkMenu';
import LinkMenuGroup from './LinkMenuGroup/LinkMenuGroup';

const GroupMenu = props => {
    return (
        <ul className={classes.ListMenu} >
            <li>
                <NavLink to='/' className={classes.TitleOption}>
                    <Title400>{props.title}</Title400>
                </NavLink>
            </li>

            {
                props.link.sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1;
                    }
                    if (a.orden < b.orden) {
                        return -1;
                    }
                    return 0;
                })
                    .filter(a => a.status)
                    .map((link, i) => {
                        return typeof link.link === 'string' ? <LinkMenu key={i} label={link.label} link={link.link} closeHandler={props.closeHandler} /> : <LinkMenuGroup key={i} label={link.label} link={link.link} closeHandler={props.closeHandler} />
                    })
            }

        </ul>
    )
};

export default GroupMenu;