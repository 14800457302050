
import { SET_SCROLL_STATE } from './../actions/listener';

const initState = {
    scrollState: true,
}


export default (state = initState, action) => {
    switch (action.type) {
        case SET_SCROLL_STATE:
            return {
                scrollState: action.scrollStatus
            }
        default:
            return state;
    }
}