import Axios from './../../axios/strapi';

export const FETCH_MENU = 'FETCH_MENU';






export const fetch_menu = () => {
    return async dispatch => {
        try {
            const menuApps = await Axios.get('menu-apps');

            const buscarMenu = (id) => {
                return menuApps.data.find(el => el.id.toString() === id.toString());
            }

            const buscarCorrelacion = (el) => {
                return el.sub_menu.map(subMenu => {
                    const menu = buscarMenu(subMenu.identificador);
                    if (menu) {
                        if (menu.sub_menu.length > 0) {
                            const child = buscarCorrelacion(menu)
                            return {
                                id: menu.id,
                                label: menu.label_link,
                                link: child,
                                orden: menu.orden,
                                status: menu.status
                            }
                        } else {
                            return {
                                id: menu.id,
                                label: menu.label_link,
                                link: menu.link,
                                orden: menu.orden,
                                status: menu.status
                            }
                        }
                    }
                });
            }

            const menu = await Axios.get('menus', {
                params: {
                    _sort: "orden:asc",
                }
            });
            const resultado = menu.data.map(option => {
                const links = option.menu_apps.map(menuOption => {
                    if (menuOption.sub_menu.length > 0) {
                        return {
                            id: menuOption.id,
                            label: menuOption.label_link,
                            link: buscarCorrelacion(menuOption),
                            orden: menuOption.orden,
                            status: menuOption.status
                        };
                    } else {
                        return {
                            id: menuOption.id,
                            label: menuOption.label_link,
                            link: menuOption.link,
                            orden: menuOption.orden,
                            status: menuOption.status
                        }
                    }
                })
                return {
                    title: option.title,
                    link: option.menu_apps.length > 0 ? links : option.link
                }
            })

            dispatch({
                type: FETCH_MENU,
                menuItems: resultado
            })


        } catch (err) {
            console.log('fetch_menu', err);
        }
    }
}