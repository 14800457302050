import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Hammer from 'react-hammerjs';


const HorizontalMoveHandler = props => {
    const onPanEndHandler = (ev) => {
        console.log(ev);
        switch (ev.additionalEvent) {
            case 'panleft':
                props.onLeft();
                break;

            case 'panright':
                props.onRight();
                break;

            default:

        }
    }

    return (
        <Hammer onPanEnd={onPanEndHandler} >
            {props.children}
        </Hammer>
    )
};

HorizontalMoveHandler.propTypes = {
    onLeft: PropTypes.func.isRequired,
    onRight: PropTypes.func.isRequired,
}

export default HorizontalMoveHandler;