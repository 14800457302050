import React from 'react';

import classes from './Loader.module.scss';

const Loader = props => {
    return (
        <div className={classes.Loader}>
            <div className={classes.First}></div>
            <div className={classes.Second}></div>
            <div className={classes.Third}></div>
        </div>
    )
};

export default Loader;