import { Icon } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../../../components/Button/Button';
import SimpleNoticiaCard from '../../../../components/SimpleNoticiaCard/SimpleNoticiaCard';
import { TitlePrincipal } from '../../../../components/Titles/Titles';
import useViewportSize from '../../../../hooks/useViewportSize/useViewportSize';
import { useHistory } from 'react-router-dom';

import classes from './MasVistas.module.scss';
import HorizontalMoveHandler from '../../../../components/HorizontalMoveHandler/HorizontalMoveHandler';

const MasVistas = props => {
    const texts = useSelector(state => state.principal.texts);
    const history = useHistory();
    const masVistas = useSelector(state => state.noticias.masVistas);

    const [actualPosition, setActualPosition] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(1);
    const [elementos, setElementos] = useState(0);

    const viewportSize = useViewportSize();

    const setStyleHandler = useCallback(() => {
        return {
            transform: `translateX(0%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        }
    }, [itemsPerView, elementos])

    const [viewPosition, setViewPosition] = useState(setStyleHandler())

    useEffect(() => {
        if (masVistas) {
            setElementos(masVistas?.length || 0);
            setViewPosition(setStyleHandler());
        }
    }, [masVistas, setStyleHandler, actualPosition])

    const containerRef = useRef();

    const animationTransitionHandler = () => {
        if (containerRef && containerRef.current) {
            containerRef.current.style.transition = null
            containerRef.current.ontransitionend = function (event) {
            }
            containerRef.current.style.transition = 'all .5s';
        }
    }

    const calculateViewerPosition = () => {
        const movement = 100 / elementos;
        animationTransitionHandler();
        setActualPosition(0)
        setViewPosition({
            transform: `translateX(-${movement * actualPosition}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }


    useMemo(() => {
        if (elementos) {
            switch (viewportSize) {
                case 'xs':
                    setItemsPerView(1);
                    break;
                case 'sm':
                    if (elementos >= 2) {
                        setItemsPerView(2);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'md':
                    if (elementos >= 3) {
                        setItemsPerView(2);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'lg':
                    if (elementos >= 4) {
                        setItemsPerView(4);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'xl':
                    if (elementos >= 5) {
                        setItemsPerView(4);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                default:
            }
        }
        calculateViewerPosition()
    }, [elementos, viewportSize])


    const nextView = () => {
        if ((elementos - itemsPerView) > (actualPosition)) {
            setActualPosition(actualPosition + 1);
        } else {
            setActualPosition(0);
        }

    }

    const prevView = () => {
        if (actualPosition === 0 && itemsPerView < elementos) {
            setActualPosition(elementos - itemsPerView);
        } else {
            setActualPosition(actualPosition - 1)
        }
    }

    useEffect(() => {
        animationTransitionHandler();
        const movement = 100 / elementos;
        setViewPosition({
            transform: `translateX(-${movement * (actualPosition)}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }, [actualPosition, itemsPerView, elementos])

    const leerMas = () => {
        history.push('/noticias')
    }

    return (
        <div className={classes.NoticiasMasVistas}>
            <div className={classes.Cabecera}>
                <TitlePrincipal className={classes.TituloPrincipal}>
                    {texts && texts.tituloNoticiasMasVistas ? texts.tituloNoticiasMasVistas : 'Noticias más vistas'}
                </TitlePrincipal>
                <div className={classes.ArrowContainer}>
                    <Icon className={classes.Arrow} onClick={prevView}>arrow_back</Icon>
                    <Icon className={classes.Arrow} onClick={nextView}>arrow_forward</Icon>
                </div>
            </div>
            <HorizontalMoveHandler onLeft={nextView} onRight={prevView}>

                <div ref={containerRef} className={classes.ListaNoticias} style={viewPosition}>

                    {
                        masVistas ?
                            masVistas.map(({ noticia }) => noticia ? <SimpleNoticiaCard
                                key={noticia.id}
                                idNoticia={noticia.id}
                                titular={noticia.Titular}
                                subtitulo={noticia.Subtitulo}
                                categoria={noticia.Categoria}
                                fechaCreacion={noticia.created_at}
                            /> : null) :
                            null
                    }

                </div>
            </HorizontalMoveHandler>
            <Button onClick={leerMas}>{texts && texts.btnLeerMasNoticias ? texts.btnLeerMasNoticias : 'Leer Más'}</Button>
        </div>
    )
};

export default MasVistas;