import React, { useEffect, useState } from 'react';

import classes from './NewsList.module.scss';
import ImportantNews from '../../../components/ImportantNews/ImportantNews';
import NotSoImportantNews from '../../../components/NotSoImportantNews';

import CardLink from './../../../components/CardLink/CardLink';

import Button from './../../../components/Button/Button';

import { TitlePrincipal } from './../../../components/Titles/Titles';


import Axios from '../../../axios/strapi';
import { useSelector } from 'react-redux';
import { BASE_URL } from '../../../constants';
import SimpleNoticiaCard from '../../../components/SimpleNoticiaCard/SimpleNoticiaCard';
import MasVistas from './MasVistas/MasVistas';
import { useDispatch } from 'react-redux';

import * as noticiasAction from './../../../store/actions/noticias';

const NewsList = props => {
    const [noticiasPrincipales, setNoticiasPrincipales] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(noticiasAction.fetch_noticias_mas_vistas());
        dispatch(noticiasAction.fetch_ultimas_noticias())
    }, [dispatch])

    const noticias = useSelector(state => state.noticias.ultimasNoticias);
    const texts = useSelector(state => state.principal.texts);

    useEffect(() => {
        if (noticias && noticias.length > 0) {
            setNoticiasPrincipales(noticias.slice(0, noticias.length - 4))
        }
    }, [noticias])

    const cardlinks = useSelector(state => state.principal.tarjetas);


    return (
        <div className={classes.NewsList}>


            <div className={classes.MainNews}>



                <div className={classes.Noticias}>
                    <TitlePrincipal>
                        {texts && texts.tituloNoticiasRecientes ? texts.tituloNoticiasRecientes : 'Noticias recientes'}
                    </TitlePrincipal>
                    <div className={classes.MainNewsPrincipal}>
                        {noticiasPrincipales.slice(0, 4).map((noticia, i) =>
                            <ImportantNews
                                key={i}
                                imagen={`${Axios.defaults.baseURL}${noticia.Fotos[0].formats.small ? noticia.Fotos[0].formats.small.url : noticia.Fotos[0].formats.thumbnail.url}`}
                                titular={noticia.Titular}
                                cuerpo={noticia.Cuerpo}
                                fechaCreacion={noticia.created_at}
                                idNoticia={noticia.id}
                                categoria={noticia.Categoria}
                            />
                        )}
                    </div>
                </div>

                <div className={classes.Links}>
                    {cardlinks ?
                        cardlinks.map(card => <CardLink
                            key={card.id}
                            titulo={card.titulo}
                            subtitulo={card.subtitulo}
                            imagen={BASE_URL + card.imagen.url}
                            buttonText={card.textoBoton}
                            linkButton={card.link}
                        />) :
                        null
                    }

                </div>

            </div>
            <div className={[classes.Noticias, classes.MinorNews].join(' ')}>
                {noticias ? noticias.slice(4, 8).map((noticia, i) =>
                    <NotSoImportantNews
                        key={i}
                        imagen={`${Axios.defaults.baseURL}${noticia.Fotos[0].formats.small ? noticia.Fotos[0].formats.small.url : noticia.Fotos[0].formats.thumbnail.url}`}
                        titular={noticia.Titular}
                        cuerpo={noticia.Cuerpo}
                        fechaCreacion={noticia.created_at}
                        idNoticia={noticia.id}
                        categoria={noticia.Categoria}
                    />
                ): null}
            </div>
            <MasVistas />


        </div>
    )
};

export default NewsList;