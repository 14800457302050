import React, { useState } from 'react';
import { EmailIcon, EmailShareButton, FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share';
import PropTypes from 'prop-types';
import classes from './ShareButton.module.scss';
import { Icon } from '@material-ui/core';

const ShareButton = props => {
    const [shareOptions, setShareOptions] = useState(false);

    const shareOptionsHandler = () => {
        setShareOptions(!shareOptions)
    }
    return (
        <div className={[classes.ShareButton, props.className || null].join(' ')} >
            <div className={classes.Share}>
                <Icon className={classes.ShareIcon} onClick={shareOptionsHandler}>share</Icon>
            </div>
            <div className={[classes.ShareOptions, shareOptions ? classes.Open : null].join(' ')}>
                <WhatsappShareButton className={classes.ShareItemOption} url={props.url} title={props.message} ><WhatsappIcon size={34} round={true}></WhatsappIcon ></WhatsappShareButton>
                <FacebookShareButton className={classes.ShareItemOption} url={props.url} quote={props.message} hashtag="#hashtag" ><FacebookIcon size={34} round={true}></FacebookIcon></FacebookShareButton>
                <TwitterShareButton className={classes.ShareItemOption} url={props.url} title={props.message} ><TwitterIcon size={34} round={true}></TwitterIcon></TwitterShareButton>
                <EmailShareButton className={classes.ShareItemOption} url={props.url} body={props.message} subject={props.message}><EmailIcon size={34} round={true}></EmailIcon></EmailShareButton>
            </div>
        </div>
    )
};

ShareButton.propTypes = {
    message: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default ShareButton;