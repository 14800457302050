import { FETCH_TIPOS_DOCUMENTOS } from "../actions/documentosInteres";

const initState = {
    tiposDocumentos:[]
}



export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_TIPOS_DOCUMENTOS:
            return {
                tiposDocumentos: action.data
            }
        default:
            return state;
    }
}