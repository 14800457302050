import Axios from './../../axios/strapi';

export const FETCH_REVISTA = 'FETCH_REVISTA';
export const CREAR_PAGINADOR_REVISTAS = 'CREAR_PAGINADOR_REVISTAS';
export const FETCH_REVISTA_PAGINADA = 'FETCH_REVISTA_PAGINADA';
export const IR_PAGINA_REVISTA = 'IR_PAGINA_REVISTA'

const ARTITULO_POR_VISTA = 12

export const fetchRevista = () => {
    return async (dipatch) => {
        try {

            const revista = await Axios.get('/revistas');

            const data = revista.data;
            const elements = revista.data.length;

            dipatch({ type: FETCH_REVISTA, data, elements })
        } catch (err) {
            throw err;
        }
    }
}

export const fetchRevistaPaginada = (vista) => {
    return async dispatch => {
        try {
            const start = (ARTITULO_POR_VISTA * (vista - 1))

            const revista = await Axios.get('revistas', {
                params: {
                    _sort: "created_at:desc",
                    _start: start,
                    _limit: ARTITULO_POR_VISTA
                }
            })

            dispatch({
                type: FETCH_REVISTA_PAGINADA,
                articulos: revista.data
            })

        } catch (err) {
            console.log('Error al buscar los articulos', err);
        }
    }
}

export const crear_paginador = () => {
    return async dispatch => {
        try {
            const countRevista = await Axios.get('revistas/count');
            const paginasTotales = Math.ceil((countRevista.data) / ARTITULO_POR_VISTA)

            dispatch({
                type: CREAR_PAGINADOR_REVISTAS,
                paginador: {
                    cantidadDePaginas: paginasTotales,
                    paginaActual: 1,
                }
            });
            fetchRevistaPaginada(1);

        } catch (err) {
            console.log('Error al biscar las noticias mas vistas ', err);
        }
    }
}

export const ir_pagina = (pagina) => {

    try {

        return {
            type: IR_PAGINA_REVISTA,
            pagina: pagina,
        }

    } catch (err) {
        console.log('Error al biscar las noticias mas vistas ', err);
    }

}