import { FETCH_MENU } from "../actions/menu";

const initState = {
    menuItems: [],
}

export default (state=initState, action) => {
    switch (action.type){
        case FETCH_MENU:
            return {
                menuItems: action.menuItems
            }
        default:
            return state;
    }
}