import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './OrganigramaMunicipal.module.scss';
import * as organigramaMunicipalActions from './../../store/actions/organigramaMunicipal';
import { TitlePrincipal } from '../../components/Titles/Titles';
import RichTextReader from '../../components/RichTextReader/RichTextReader';
import ImageLoader from '../../components/ImageLoader/ImageLoader';

const OrganigramaMunicipal = props => {


    window.scroll({
        top: 0,
        behavior: 'smooth'
    })


    const presentacion = useSelector(state => state.organigramaMunicipal.presentacion)
    const imagen = useSelector(state => state.organigramaMunicipal.imagen)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(organigramaMunicipalActions.fetch_organigrama())
    }, [dispatch])


    return (
        <div className={classes.OrganigramaMunicipal}>
            <TitlePrincipal>
                Organigrama Municipal
            </TitlePrincipal>
            {presentacion ? <RichTextReader bodyText={presentacion} /> : null}
            {imagen ? <ImageLoader  imagenPrincipal={imagen} /> : null}

        </div>
    )
};

export default OrganigramaMunicipal;