import Axios from './../../axios/strapi';

export const FETCH_DIRECCIONES = 'FETCH_DIRECCIONES';


export const fetch_direcciones = () => {
    return async dispatch => {

        const direciones = await Axios.get('direccions');


        dispatch({
            type: FETCH_DIRECCIONES,
            direciones_municipales: direciones.data
        })
    }
}