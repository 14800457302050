import Axios from './../../axios/strapi';

export const FETCH_DATA_MODAL = 'FETCH_DATA_MODAL';
export const CERRAR_MODAL = 'CERRAR_MODAL';

export const fetchModalData = () => {
    return async dispatch => {
        const modal = await Axios.get('modal-viewer');

        dispatch({
            type: FETCH_DATA_MODAL,
            modal: modal.data
        })

    }
}

export const cerrarModal = () => {
    return {
        type: CERRAR_MODAL
    }
}