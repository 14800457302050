import React, { useEffect, useRef, useState } from 'react';
import ImportantNews from '../../components/ImportantNews/ImportantNews';
import { T36 } from '../../components/Titles/Titles';

import classes from './ListaNoticias.module.scss';
import * as noticiasAction from './../../store/actions/noticias';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../constants';
import PaginadorElementos from '../../components/PaginadorElementos/PaginadorElementos';
import NoticiaSimple from './NoticiaSimple/NoticiaSimple';


const ListaNoticias = props => {


    useEffect(() => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        })

    }, [])


    const [noticiasPrincipales, setNoticiasPrincipales] = useState([]);
    const [noticiasSecundarias, setNoticiasSecundarias] = useState([]);
    const dispatch = useDispatch();

    window.setTituloSitio(`Noticias`);
    const noticiasAnterioresRef = useRef();


    const noticias = useSelector(state => state.noticias.ultimasNoticias);
    const noticiasAnteriores = useSelector(state => state.noticias.noticiasAnteriores)
    const paginador = useSelector(state => state.noticias.paginador)
    const masVistas = useSelector(state => state.noticias.masVistas);

    useEffect(() => {
        dispatch(noticiasAction.fetch_noticias_mas_vistas());
        dispatch(noticiasAction.fetch_ultimas_noticias())
        dispatch(noticiasAction.fetch_noticias_anteriores(1));
        dispatch(noticiasAction.crear_paginador());
    }, [dispatch])



    useEffect(() => {
        if (noticias && noticias.length > 0) {
            setNoticiasPrincipales(noticias.slice(0, 4))
            setNoticiasSecundarias(noticias.slice(4, 8))
        }
    }, [noticias])

    const paginadorHandler = (pagina) => {
        console.log(noticiasAnterioresRef.current.offsetTop);
        window.scrollTo({
            top: noticiasAnterioresRef.current.offsetTop - 80,
            behavior: 'smooth'
        })
        dispatch(noticiasAction.ir_pagina(pagina));
        dispatch(noticiasAction.fetch_noticias_anteriores(pagina));
    }

    return (
        <div className={classes.ListaNoticias}>

            <section>
                <T36 left>Noticias Recientes</T36>
                <div className={classes.NoticiasImportantes}>
                    {noticiasPrincipales.map((noticia, i) =>
                        <div key={i} className={classes.ContenedorNoticia}>
                            <ImportantNews
                                className={classes.Noticia}
                                imagen={`${BASE_URL}${noticia.Fotos[0].formats.small ? noticia.Fotos[0].formats.small.url : noticia.Fotos[0].formats.thumbnail.url}`}
                                titular={noticia.Titular}
                                cuerpo={noticia.Cuerpo}
                                fechaCreacion={noticia.created_at}
                                idNoticia={noticia.id}
                                categoria={noticia.Categoria}
                            />
                        </div>
                    )}
                </div>
                <div className={classes.OtrasNoticias}>
                    {noticiasSecundarias.map((noticia, i) =>
                        <div key={i} className={classes.ContenedorNoticia}>
                            <ImportantNews
                                className={classes.Noticia}
                                imagen={`${BASE_URL}${noticia.Fotos[0].formats.small ? noticia.Fotos[0].formats.small.url : noticia.Fotos[0].formats.thumbnail.url}`}
                                titular={noticia.Titular}
                                cuerpo={noticia.Cuerpo}
                                fechaCreacion={noticia.created_at}
                                idNoticia={noticia.id}
                                categoria={noticia.Categoria}
                            />
                        </div>
                    )}
                </div>

            </section>
            <section>
                <T36 left>Noticias más vistas</T36>
                <section className={classes.Mas_vistas}>
                    {
                        masVistas ?
                            masVistas.map(({ noticia }) => noticia ? <NoticiaSimple
                                key={noticia.id}
                                idNoticia={noticia.id}
                                titular={noticia.Titular}
                                subtitulo={noticia.Subtitulo}
                                categoria={noticia.Categoria}
                                fechaCreacion={noticia.created_at}
                            /> : null) :
                            null
                    }
                </section>
            </section>
            <section ref={noticiasAnterioresRef} >
                <T36 left>Noticias anteriores</T36>
                <div className={classes.NoticiasPaginadas}>
                    <div className={classes.Paginador}>
                        {paginador ? <PaginadorElementos totalPaginas={paginador.cantidadDePaginas} pagePosition={paginador.paginaActual} pagePositionHandler={paginadorHandler} /> : null}
                    </div>
                    {noticiasAnteriores ? noticiasAnteriores.map((noticia, i) =>
                        <div key={i} className={classes.ContenedorNoticia}>
                            <ImportantNews
                                className={classes.Noticia}
                                imagen={`${BASE_URL}${noticia.Fotos[0].formats.small ? noticia.Fotos[0].formats.small.url : noticia.Fotos[0].formats.thumbnail.url}`}
                                titular={noticia.Titular}
                                cuerpo={noticia.Cuerpo}
                                fechaCreacion={noticia.created_at}
                                idNoticia={noticia.id}
                                categoria={noticia.Categoria}
                            />
                        </div>
                    ) : null}
                    <br />
                    <div className={classes.Paginador}>
                        {paginador ? <PaginadorElementos totalPaginas={paginador.cantidadDePaginas} pagePosition={paginador.paginaActual} pagePositionHandler={paginadorHandler} /> : null}
                    </div>
                </div>
            </section>
        </div>
    )
};

export default ListaNoticias;