import React from 'react';
import { Link } from 'react-router-dom';


const ResolveLink = props => {
    if (props.link.match(/^http/)) {
        return (
            <a draggable={false} className={props.className} href={props.link} target="_blank" rel="noopener noreferrer" categoria={props.categoria} etiqueta={props.etiqueta}>
                {props.children}
            </a>
        )
    } else {
        return (
            <Link to={Link} className={props.className} categoria={props.categoria} etiqueta={props.etiqueta}>
                {props.children}
            </Link>
        )
    }
};

export default ResolveLink;