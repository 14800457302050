import React from 'react';
import classes from './Titles.module.scss';
import { useSelector } from 'react-redux';

const Wrapper = props => {
    const fontSize = useSelector(state => { return state.globalStyles.fontSize });
    return (
        <div style={{ fontSize: fontSize + 'px' }}>
            {props.children}
        </div>
    )
}

export const TitlePrincipal = props => {

    return (
        <div className={classes.TitlePrincipal} style={props.style}>
            <Title100>
                {props.children}
            </Title100>
        </div>
    )

}

export const Title100 = props => {

    let style = {}
    let classList = [classes.Title100]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }


    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Title200 = props => {

    let style = {}
    let classList = [classes.Title200]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }


    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Title300 = props => {

    let style = {}
    let classList = [classes.Title300]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Title400 = props => {

    let style = {}
    let classList = [classes.Title400]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={{ ...style, textAlign: props.left ? "left" : "" }}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Title500 = props => {

    let style = {}
    let classList = [classes.Title500]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const T36 = props => {
    let style = {}
    let classList = [classes.T36]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
}
export const T30 = props => {
    let style = {}
    let classList = [classes.T30]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
}

export const T24 = props => {

    let style = {}
    let classList = [classes.T24]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const T20 = props => {

    let style = {}
    let classList = [classes.T20]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const T16 = props => {

    let style = {}
    let classList = [classes.T16]

    if (props.style) {
        style = { ...props.style }
    }

    if (props.className) {
        classList.push(props.className);
    }

    if (props.left) {
        classList.push(classes.Left)
    }

    return (
        <Wrapper>
            <p className={classList.join(' ')} style={style}>
                {props.children}
            </p>
        </Wrapper>
    )
};

