import React from 'react';

import { TitlePrincipal } from './../../../components/Titles/Titles';
import Button from './../../../components/Button/Button';
import { useSelector } from 'react-redux';
import Viewer from './Viewer/Viewer';
import { useHistory } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';

const Revista = props => {
    const history = useHistory()
    const articulos = useSelector(state => state.principal.articulos);
    const texts = useSelector(state => state.principal.texts);

    const verMas = () => {
        history.push('/revista')
    }

    return (
        <div style={{ padding: '10px' }}>

            <TitlePrincipal>
                {texts && texts.titulorRevistasYVideos ? texts.titulorRevistasYVideos : 'Revistas y videos'}
            </TitlePrincipal>
            {articulos ? <Viewer articulos={{
                items: articulos.items
                    .sort((a, b) => {
                        if (a.id < b.id) {
                            return 1;
                        }
                        if (a.id > b.id) {
                            return -1;
                        }
                        return 0;
                    }).filter((x, i) => i < 6),
                itemsNumber: 6
            }}></Viewer> : <Loader />}
            <Button onClick={verMas}>{texts && texts.btnVerMasRevisatasYVideos ? texts.btnVerMasRevisatasYVideos : 'Ver mas'}</Button>


        </div>
    )
};

export default Revista