import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './Turismo.module.scss';
import Mirador from './Mirador/Mirador';
import PlaceCard from './PlaceCard/PlaceCard';
import PlaceLink from './PlaceLink/PlaceLink';
import * as mainActions from './../../store/actions/prueba';
import { BASE_URL } from '../../constants';

const Turismo = props => {
    const [place, setPlace] = useState(null);
    const [links, setLinks] = useState(null);
    const [sitiosTuristicos, setSitiosTuristicos] = useState(null);
    const dispatch = useDispatch();

    window.scroll({
        top: 0,
        behavior: 'smooth'
    })
    const placesList = useSelector(state => state.principal.places);

    useEffect(() => {
        if (!placesList || placesList.length === 0) {
            dispatch(mainActions.fetch_data());
        }
    }, [dispatch])

    useEffect(() => {
        if (
            placesList
        ) {
            const placeId = props.match.params.id
            const selectedPlace = placesList.find(p => p.id === parseInt(placeId, 10))
            const placesListLink = placesList.filter(p => p.id !== parseInt(placeId, 10))

            setPlace(selectedPlace);
            setLinks(placesListLink);
            if (selectedPlace) {
                setSitiosTuristicos(selectedPlace.sitios_turisticos);
            }

        }

    }, [placesList, setPlace, props.match.params.id]);

    let mirador = null;
    let renderLinks = null;
    let lugares = null;
    if (place) {
        mirador = <Mirador color={place.color} titulo={place.titulo} imagenPrincipal={BASE_URL + place.imagenPrincipal.url}></Mirador>
        renderLinks = links.map(place => <PlaceLink key={place.id} placeId={place.id} label={place.titulo} color={place.color} />)
        lugares = sitiosTuristicos.map(p => <PlaceCard key={p.id} title={p.titulo} color={place.color} imagenPrincipal={BASE_URL + p.imagen_principal.url} link={p.link} />)
        console.log(sitiosTuristicos);
    }



    return (
        <div>
            {mirador}

            <div className={classes.Container}>

                <div className={classes.LinkList}>
                    {renderLinks}
                </div>


                <div className={classes.Lugares}>
                    {lugares}

                </div>

            </div>
        </div>
    )
};

export default Turismo;