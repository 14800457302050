import Axios from "./../../axios/strapi"

export const FETCH_MAS_VISTAS = 'FETCH_MAS_VISTAS';
export const FETCH_NOTICIAS = 'FETCH_NOTICIAS';
export const FETCH_ULTIMAS_NOTICIAS = 'FETCH_ULTIMAS_NOTICIAS';
export const FETCH_NOTICIAS_ANTERIORES = 'FETCH_NOTICIAS_ANTERIORES';
export const CREAR_PAGINADOR = 'CREAR_PAGINADOR';
export const IR_PAGINA = 'IR_PAGINA';


const ULTIMAS_NOTICIAS = 8;
const NOTICIAS_POR_VISTA = 12;


export const aumentar_contador = (id) => {
    return async dispatch => {
        try {

            const resp = await Axios.get('noticias-mas-vistas', {
                params: {
                    noticia: id
                }
            })

            let response = null

            if (resp.status === 200 && resp.data.length === 1) {
                response = await Axios.put('noticias-mas-vistas/' + resp.data[0].id, {
                    viewers: resp.data[0].viewers + 1
                })
            } else {
                response = Axios.post('noticias-mas-vistas', {
                    viewers: 1,
                    noticia: id
                })
            }
        } catch (err) {
            console.log('Error al intentar aumentar vistas ', err)
        }
    }
}

export const fetch_noticias_mas_vistas = () => {
    return async dispatch => {
        try {
            const noticias = await Axios.get('noticias', {
                params: {
                    _sort: "created_at:desc",
                    _limit: 12
                }
            })

            dispatch({
                type: FETCH_ULTIMAS_NOTICIAS,
                ultimasNoticias: noticias.data
            })

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

export const fetch_ultimas_noticias = () => {
    return async dispatch => {
        try {
            const noticias = await Axios.get('noticias-mas-vistas', {
                params: {
                    _sort: "viewers:desc",
                    _limit: ULTIMAS_NOTICIAS
                }
            })

            dispatch({
                type: FETCH_MAS_VISTAS,
                masVistas: noticias.data
            })

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

export const crear_paginador = () => {
    return async dispatch => {
        try {
            const countNoticias = await Axios.get('noticias/count');

            const paginasTotales = Math.ceil((countNoticias.data - ULTIMAS_NOTICIAS) / NOTICIAS_POR_VISTA)

            dispatch({
                type: CREAR_PAGINADOR,
                paginador: {
                    cantidadDePaginas: paginasTotales,
                    paginaActual: 1,
                }
            });
            fetch_noticias_anteriores(1)

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

export const ir_pagina = (pagina) => {

    try {

        return {
            type: IR_PAGINA,
            pagina: pagina,
        }

    } catch (err) {
        console.log('Error al buscar las noticias mas vistas ', err);
    }

}


export const fetch_noticias_anteriores = (page) => {
    return async dispatch => {
        try {

            const start = (NOTICIAS_POR_VISTA * page) - (NOTICIAS_POR_VISTA - ULTIMAS_NOTICIAS)
            const limit = NOTICIAS_POR_VISTA;

            const noticias = await Axios.get('noticias', {
                params: {
                    _sort: "created_at:desc",
                    _start: start,
                    _limit: limit
                }
            })


            dispatch({
                type: FETCH_NOTICIAS_ANTERIORES,
                noticiasAnteriores: noticias.data
            })

        } catch (err) {
            console.log('Error al buscar las noticias mas vistas ', err);
        }
    }
}

