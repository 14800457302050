import React from 'react';
import classes from './LinkDeInteres.module.scss';
import { Icon } from '@material-ui/core';
import { Paragraph200, Paragraph300 } from './../../../../components/Paragraph/Paragraph';

const LinkDeInteres = props => {

    const capitalize = s => {
        if (typeof s !== 'string') return ''
        return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    }


    return (
        <div className={classes.Container}>
            <a className={classes.LinkDeInteres} href={props.link} target="_blank" rel="noopener noreferrer">
                <Icon className={classes.Icon}>{props.iconName}</Icon>
                <div className={classes.Label}>
                    <Paragraph200>{capitalize(props.title)}</Paragraph200>
                    <Paragraph300>{capitalize(props.label)}</Paragraph300>
                </div>
            </a>
        </div>
    )
};

export default LinkDeInteres;