import React, { useEffect } from 'react';


import classes from './Footer.module.scss';
import footer from './../../assets/images/1x/footerTop.png';
import logo from './../../assets/images/1x/Logo.png';

import { Paragraph100, Paragraph200 } from './../Paragraph/Paragraph';
import { useDispatch, useSelector } from 'react-redux';
import * as footerActions from './../../store/actions/footer';
import Contacto from './Contato/Contacto';
import Ministerio from './Ministerio/Ministerio';
import RedSocialLink from './RedSocialLink/RedSocialLink';

const Footer = props => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(footerActions.fetchFooterContactos());
        dispatch(footerActions.fetchFooterMinisterios());
        dispatch(footerActions.fetchRedSocial());
        dispatch(footerActions.fetchDireccion());
    }, [dispatch])

    const contactos = useSelector(state => state.footer.contacto)
    const ministerios = useSelector(state => state.footer.ministerios)
    const socials = useSelector(state => state.footer.social)
    const direccion = useSelector(state => state.footer.direccion)

    return (
        <section className={classes.Footer}>

            <img
                src={footer}
                className={classes.FooterImage}
                alt="wave"
            />
            <div className={classes.FooterContent}>
                {/* <div className={classes.BgImage}></div> */}

                <div className={classes.ContainerLogo}>

                    <div className={classes.ImageContainer}>
                        <img src={logo} alt="img"></img>
                    </div>


                    {/* <div className={classes.DireccionContainer}>
                        {direccion && direccion.direccion ? <p className={classes.Titulo}>DIRECCION: <span>{direccion && direccion.direccion}</span></p>: null}
                        {direccion && direccion.telefono ? <p className={classes.Titulo}>TELEFONO: <span>{direccion && direccion.telefono}</span></p>: null}
                        {direccion && direccion.mail ? <p className={classes.Titulo}>EMAIL: <span>{direccion && direccion.mail}</span></p>: null }
                    </div> */}
                </div>

                <div className={classes.Enlaces}>

                    <div className={classes.FooterLink}>
                        <div className={classes.ContactoTelefonico}>
                            {/* <p className={classes.Titulo}>Contactos y emergencias</p> */}
                            {contactos.map(contacto => <Contacto key={contacto.id} nombreContacto={contacto.contacto} contacto={contacto.numero} />)}
                        </div>

                        <div className={classes.DireccionContainer}>
                            {direccion && direccion.direccion ? <p className={classes.Titulo}>DIRECCION: <span style={{ display: 'block' }}>{direccion && direccion.direccion}</span></p> : null}
                            {direccion && direccion.telefono ? <p className={classes.Titulo}>TELEFONO: <span style={{ display: 'block' }}>{direccion && direccion.telefono}</span></p> : null}
                            {direccion && direccion.mail ? <p className={classes.Titulo}>EMAIL: <span style={{ display: 'block' }}>{direccion && direccion.mail}</span></p> : null}
                        </div>
                        {/* 
                        <div className={classes.Ministerios}>
                            <p className={classes.Titulo} >Ministerio de Chile</p>
                            {ministerios.map(ministerio => <Ministerio key={ministerio.id} ministerio={ministerio.ministerio} link={ministerio.link} />)}
                        </div> */}
                    </div>




                    <div className={classes.RedesSociales}>
                        <Paragraph100>Encuentranos en:</Paragraph100>
                        <div>
                            {socials.map(s => <RedSocialLink key={s.id} link={s.link} redSocial={s.red_social} />)}
                        </div>
                    </div>

                </div>

            </div>

            <Paragraph200 className={classes.Copyright}>&copy; 2021, sitio web construido por 2Reflex</Paragraph200>


        </section>
    )
};

export default Footer;