import React, { useRef, useEffect, useState } from 'react';
import Icon from '@material-ui/core/Icon';

import classes from './Button.module.scss';
import { Paragraph200 } from './../Paragraph/Paragraph';

const Button = props => {
    const [top, setTop] = useState(0)
    const [left, setLeft] = useState(0)

    useEffect(() => {
        btnRef.current.addEventListener('click', (event) => {

            rippleRef.current.style.top = top + 'px'
            rippleRef.current.style.left = left + 'px'
            rippleRef.current.classList.remove(classes.Click);
            setTop(event.offsetY);
            setLeft(event.offsetX)
            rippleRef.current.style.top = event.offsetY + 'px'
            rippleRef.current.style.left = event.offsetX + 'px'
            rippleRef.current.classList.add(classes.Click);

        })
    }, []);

    const btnRef = useRef();
    const rippleRef = useRef();

    return (
        <div ref={btnRef} className={classes.Button} style={props.style} onClick={props.onClick}>
            <div className={classes.Content}>
                {props.icon ? <Icon>{props.icon || 'add'}</Icon> : null}
                <Paragraph200 style={{ 'paddingLeft': '10px' }}>{props.children}</Paragraph200>
            </div>
            <div ref={rippleRef} className={classes.Ripple}></div>
        </div>
    )
};

export default Button;