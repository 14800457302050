import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Route, NavLink, Switch, Redirect } from "react-router-dom";

import Main from './Main/Main';
import News from './News/News';
import Footer from './../components/Footer/Footer';

import Alcalde from './Alcalde/Alcalde';
import NavBar from './../components/NavBar/NavBar';
import Accessibility from './../components/Accessibility/Accessibility';
import VideoModal from './../components/VideoModal/VideoModal';
import Turismo from './Turismo/Turismo';
import Cartagena from './Turismo/Cartagena/Cartagena';
import useViewportSize from '../hooks/useViewportSize/useViewportSize';
import { useDispatch, useSelector } from 'react-redux';
import * as globalStyleActions from './../store/actions/globalStyles';
import ConsejoMunicipal from './ConsejoMunicipal/ConsejoMunicipal';
import OrganigramaMunicipal from './OrganigramaMunicipal/OrganigramaMunicipal';
import DireccionTemplate from './DireccionTemplate/DireccionTemplate';
import Revista from './Revista/Revista';
import ConsejoSociedadCivil from './ConsejoSociedadCivil/ConsejoSociedadCivil';
import ListaNoticias from './ListaNoticias/ListaNoticias';
import DocumentosInteres from './DocumentosInteres';
import InformationBanner from '../components/InformationBanner/InformationBanner';
import ModalViewer from './ModalViewer/ModalViewer';

import * as direccionesActions from './../store/actions/direcciones';
import * as informationBannerActions from './../store/actions/InformationBanner';
import * as modalActions from './../store/actions/modal';

const Layout = props => {
    const [modalState, setModalState] = useState(false);

    const dispatch = useDispatch();

    const { showInformationBanner } = useSelector(state => state.informationBanner);
    const { status } = useSelector(state => state.modal)

    useEffect(() => {
        dispatch(direccionesActions.fetch_direcciones());
        dispatch(informationBannerActions.fetchInformationBanner());
        dispatch(modalActions.fetchModalData());
        const status = sessionStorage.getItem('showed');
        setModalState(status);
    }, [dispatch]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', backgroundColor: '#F2F2F2' }}>
            { (status && !modalState) && <ModalViewer />}
            {showInformationBanner && <InformationBanner />}
            <VideoModal></VideoModal>
            <NavBar></NavBar>
            <Accessibility></Accessibility>
            <div style={{ flexGrow: 2 }}>
                <Switch>
                    <Route path="/turismo/3" component={Cartagena} />
                    <Route path="/turismo/:id" component={Turismo} />
                    <Route path="/alcalde" component={Alcalde} />
                    <Route path="/noticia" component={News} />
                    <Route path="/noticias" component={ListaNoticias} />
                    <Route path="/revista" component={Revista} />
                    <Route path="/documentos-interes" component={DocumentosInteres} />
                    <Route path="/consejo-municipal" component={ConsejoMunicipal} />
                    <Route path="/consejo-sociedad-civil" component={ConsejoSociedadCivil} />
                    <Route path="/organigrama-municipal" component={OrganigramaMunicipal} />
                    <Route path="/direccion/:id" component={DireccionTemplate} />
                    <Route path="/" component={Main} />
                </Switch>
            </div>
            <Footer></Footer>
        </div>
    )
};

export default Layout;