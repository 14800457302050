import { Icon } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './Menu.module.scss';
import EstaCambiando from './../../../assets/images/SVG/esta_cambiando.svg';
import LogoSVG from './../../../assets/images/SVG/logo_solo.svg';
import * as menuActions from './../../../store/actions/menu';
import GroupMenu from '../GroupMenu/GroupMenu';
import { Link } from 'react-router-dom';

const Menu = props => {
    const dispatch = useDispatch();
    const menuItems = useSelector(state => state.menu.menuItems);

    useEffect(() => {
        dispatch(menuActions.fetch_menu());
    }, [dispatch])

    return (
        <div className={[classes.Menu, props.desplegado ? classes.Desplegado : ''].join(' ')}>

            <div className={classes.MenuContainer}>

                <div className={classes.MenuOptions}>
                    <div className={classes.MenuHeader}>

                        <div className={classes.Cartagena}>
                            <Link to="/" onMouseUp={()=>window.setMenuDesplegado()}>
                                <img src={LogoSVG} alt="logo municipalidad municipalidad de Cartagena" />
                                <img src={EstaCambiando} alt="cartagena esta cambiando" />
                            </Link>
                        </div>

                        <div className={classes.Cerrar} onClick={props.closeMenuHandler}>
                            <Icon className={classes.CloseButton} >close</Icon>
                            <div className={classes.CerrarLabel}>CERRAR</div>
                        </div>

                    </div>
                    <div className={classes.MenuItems}>
                        {
                            menuItems.length > 0 && menuItems.map(menuItem => <GroupMenu key={menuItem.id + menuItem.title} link={menuItem.link} title={menuItem.title} closeHandler={props.closeMenuHandler} />)
                        }

                    </div>
                </div>

            </div>

        </div>
    )
};

export default Menu;