import React from 'react';
import { Link } from 'react-router-dom';
import ImageLoader from '../../../../ImageLoader/ImageLoader';

import classes from './ResultElement.module.scss';
import * as buscadorActions from './../../../../../store/actions/buscador';
import { useDispatch } from 'react-redux';

const ResultElement = props => {
    const dispatch = useDispatch();
    return (
        <Link to={`/noticia?id=${props.idNoticia}`} className={classes.ResultElement} onClick={() => {
            dispatch(buscadorActions.desactivarVistaBuscador())
            dispatch(buscadorActions.limpiarBuscador())
        }}>
            <ImageLoader className={classes.ImagenPrincipal} imagenPrincipal={props.imagen} />
            <div className={classes.BajadaTexto}>
                <p className={classes.Titular}>{props.titulo}</p>
                <p className={classes.Bajada}>{props.bajada}</p>
            </div>
        </Link>
    )
};

export default ResultElement;