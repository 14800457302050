import React, { useState } from 'react';
import Icon from '@material-ui/core/Icon';
import { useDispatch, useSelector } from 'react-redux';

import classes from './NavBar.module.scss';

import Logo from '../Logo/Logo';

import { Link } from 'react-router-dom';
import { useScroll } from '../../hooks/useScroll/useScroll';
import Menu from './Menu/Menu';
import BuscadorInput from './BuscadorInput/BuscadorInput';
import * as buscardorActions from './../../store/actions/buscador';

const NavBar = () => {

    const [menuDesplegado, setMenuDesplegado] = useState(false);

    window.setMenuDesplegado = setMenuDesplegado;
    const buscadorStatus = useSelector(state => state.buscador.activado);
    const { showInformationBanner } = useSelector(state => state.informationBanner);

    const dispatch = useDispatch();
    const banner = true;
    const classList = [classes.NavBar]
    if (showInformationBanner) {
        classList.push(classes.ActiveBanner)
    }


    const scroll = useScroll()

    let style = (scroll.scrollY) > 0 && banner ? { transform: 'translateY(0px)' } : {}

    return (
        <>
            <nav className={classList.join(' ')} style={style}>

                <Link to='/' className={classes.Header}>
                    <Logo />
                    <p className={classes.Municipalidad}>Municipalidad de Cartagena</p>
                </Link>

                <div className={classes.Icon} onClick={() => { window.dataLayer.push({ event: "click", categoria: "menu", accion: "click", etiqueta: "abrir-menu" }); setMenuDesplegado(!menuDesplegado) }}>
                    <Icon className={classes.MenuIcon}>
                        menu
                    </Icon>
                    <p className={classes.MenuWord}>MENU</p>
                </div>

                <div className={classes.Buscador} onClick={() => dispatch(buscardorActions.activarVistaBuscador())}>
                    <Icon>
                        search
                    </Icon>
                </div>

                <Menu desplegado={menuDesplegado} closeMenuHandler={() => setMenuDesplegado(false)} />

                <BuscadorInput buscadorStatus={buscadorStatus} />




                <div className={[classes.Overlay, !menuDesplegado && classes.Hide].join(' ')} onClick={() => setMenuDesplegado(false)}></div>

            </nav>
        </>
    )
};

export default NavBar;

