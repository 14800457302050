import { SHOW_INFORMATION_BANNER } from "../actions/InformationBanner"

const initState = {
    showInformationBanner: false,
    information: null,
}

export default (state = initState, action) => {
    switch (action.type) {
        case SHOW_INFORMATION_BANNER:
            return {
                showInformationBanner: true,
                information: {
                    information: action.informacion,
                    link: action.link
                }
            }
        default:
            return state
    }
}