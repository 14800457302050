import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultimediaItem from '../../components/MultimediaItem/MultimediaItem';
import { TitlePrincipal } from '../../components/Titles/Titles';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import classes from './DocumentosInteres.module.scss';
import * as documentosInteresActions from './../../store/actions/documentosInteres';
import { BASE_URL } from '../../constants';
import PaginadorElementos from './../../components/PaginadorElementos/PaginadorElementos';

const VisualizadorDocumento = props => {
    const { documentos, label } = props;
    const url = documentos.length > 0 ? documentos[0].url : "";
    return (
        <a href={`${BASE_URL}${url}`} target="_target" className={classes.nonSelectable}>
            <div className={classes.preview}>
                <Document file={`${BASE_URL}${url}`} className={classes.previewDocument}>
                    <Page pageNumber={1} />
                </Document>
                <div className={classes.tituloDoc}>
                    {label}
                </div>
            </div>
        </a>
    );
}
const DocumentosInteres = props => {
    const dispatch = useDispatch();

    const tiposDocumentos = useSelector(state => state.documentosInteres.tiposDocumentos)

    window.setTituloSitio(`Documentos de Interés | Municipalidad de Cartagena`);
    const articulos = [];
    const paginador = [];

    window.scroll({
        top: 0,
        behavior: 'smooth'
    })

    useEffect(() => {
        dispatch(documentosInteresActions.fetchTiposDocumentos())
        //dispatch(revistaActions.fetchRevistaPaginada(1))
    }, [dispatch]);

    // const viewElementHandler = (elementos) => {
    //     setPageElements(elementos);
    // }

    const paginadorHandler = (pagina) => {
        console.log(pagina);
        //dispatch(revistaActions.ir_pagina(pagina));
        //dispatch(revistaActions.fetchRevistaPaginada(pagina));
    }

    return (
        <div className={classes.Revista}>
            {tiposDocumentos && tiposDocumentos.map(tipoDocumento => (
                <div>
                    <TitlePrincipal style={{marginBottom: "-20px"}}> {tipoDocumento.tipo}</TitlePrincipal>
                    <section className={classes.Articulos}>
                        {
                            tipoDocumento.documentos && tipoDocumento.documentos.length > 0 ?
                                tipoDocumento.documentos.map(item => <VisualizadorDocumento key={item.id} documentos={item.documentos} label={item.titulo} />)
                                : <p>Sin items</p>
                        }
                    </section>
                </div>))}
        </div>
    )
};

export default DocumentosInteres;