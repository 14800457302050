import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './../Turismo.module.scss';
import classesCartagena from './Cartagena.module.scss';
import Mirador from '../Mirador/Mirador';
import PlaceLink from '../PlaceLink/PlaceLink';
import * as mainActions from '../../../store/actions/prueba';
import * as cartagenaActions from '../../../store/actions/cartagena';
import { BASE_URL } from '../../../constants';
import ImageLoader from '../../../components/ImageLoader/ImageLoader';
import GaleriaCartagena from './GaleriaCartagena/GaleriaCartagena';
import EntradaDeTexto from './EntradaDeTexto/EntradaDeTexto';

const Cartagena = props => {
    const [place, setPlace] = useState(null);
    const [links, setLinks] = useState(null);
    const dispatch = useDispatch();

    window.scrollTo(0, 0)
    const placesList = useSelector(state => state.principal.places);
    const cartagena = useSelector(state => state.cartagena);

    useEffect(() => {
        if (!placesList || placesList.length === 0) {
            dispatch(mainActions.fetch_data());
        }
        if (!cartagena) {
            dispatch(cartagenaActions.fetch_cartagena());
        }
    }, [dispatch, placesList, Cartagena])

    useEffect(() => {
        if (placesList) {
            const placeId = 3
            const selectedPlace = placesList.find(p => p.id === parseInt(placeId, 10))
            const placesListLink = placesList.filter(p => p.id !== parseInt(placeId, 10))

            setPlace(selectedPlace);
            setLinks(placesListLink);
        }

    }, [placesList, setPlace]);

    let mirador = null;
    let renderLinks = null;
    if (place) {
        mirador = <Mirador color={place.color} titulo={place.titulo} imagenPrincipal={BASE_URL + place.imagenPrincipal.url}></Mirador>
        //renderLinks = links.map(place => <PlaceLink key={place.id} placeId={place.id} label={place.titulo} color={place.color} />)
    }

    return (
        <div className={classesCartagena.Cartagena}>
            {mirador}
            <div className={classes.Container}>

                <div className={classes.LinkList}>
                    {renderLinks}
                </div>

                <div className={classesCartagena.Contenido}>

                    <div className={classesCartagena.Entrada} >
                        {cartagena ? <ImageLoader imagenPrincipal={cartagena.imagenMapa} /> : null}
                        <EntradaDeTexto className={classesCartagena.Parrafo} textos={cartagena ? cartagena.primeraEntrada : []} />
                    </div>

                    {cartagena ? <ImageLoader imagenPrincipal={cartagena.imagenPrincipal} /> : null}

                    <EntradaDeTexto textos={cartagena ? cartagena.segundaEntrada : []} />

                    <GaleriaCartagena galeria={cartagena ? cartagena.galeria : []} />

                </div>



            </div>
        </div>
    )
};

export default Cartagena;