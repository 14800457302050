import Axios from './../../axios/strapi';

export const FETCH_DATA = 'FETCH_DATA';
export const FETCH_CONTACTO_EMERGENCIA = 'FETCH_CONTACTO_EMERGENCIA';
export const FETCH_MAIN_TEXTS = 'FETCH_MAIN_TEXTS';

export const fetch_data = () => {
    return async (dispatch) => {

        const linkGobierno = await Axios.get('link-gobiernos');
        // fetch Revistas
        const revista = await Axios.get('/revistas', {
            params: {
                _sort: "created_at:desc",
                _limit: 8
            }
        });
        const data = revista.data;
        const elements = revista.data.length;
        // fetch Places
        const turismo = await Axios.get('/turismos')
        const socialLink = await Axios.get('redes-sociales');
        const linkInteres = await Axios.get('link-interes');
        const tarjetas = await Axios.get('tarjeta-informacions', {
            params: {
                estado: true,
                _sort: "orden:asc",
                _limit: 6
            }
        });
        const contactoEmergencia = await Axios.get('contacto-denuncia')




        dispatch({
            type: FETCH_DATA,
            linksGobierno: linkGobierno.data,
            revista: { items: data, itemsNumber: elements },
            places: turismo.data,
            socialLink: socialLink.data,
            linkInteres: linkInteres.data,
            tarjeta: tarjetas.data,
            contactoEmergencia: contactoEmergencia.data
        })
    }
}

export const fetchTexts = () => {
    return async dispatch => {
        const mainTexts = await Axios.get('main-texts');

        dispatch({
            type: FETCH_MAIN_TEXTS,
            texts: mainTexts.data
        })
    }
}

export const fetchContactoEmergencia = () => {
    return async (dispatch) => {
        const contactoEmergencia = await Axios.get('contacto-denuncia')

        dispatch({
            type: FETCH_DATA,
            contactoEmergencia: contactoEmergencia.data
        })
    }
}