import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classes from './VideoModal.module.scss';
import * as videoModalActions from './../../store/actions/videoModal';

const VideoModal = props => {

    const opened = useSelector(state => state.videoModal.opened);
    const videoUrl = useSelector(state => state.videoModal.videoUrl);
    const dispatch = useDispatch();

    const closeModalHandler = () => {
        dispatch(videoModalActions.cerrarModal())
    }

    return (
        opened ? <div className={classes.VideoModal} onClick={closeModalHandler}>
            <video width="320" controls className={classes.Video}>
                <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
            </video>
        </div> : null
    )
};

export default VideoModal;