import React from 'react';
import classes from './Paragraph.module.scss';
import { useSelector } from 'react-redux';

const Wrapper = props => {
    const fontSize = useSelector(state => { return state.globalStyles.fontSize });
    return (
        <div style={{ fontSize: fontSize + 'px' }} className={props.className}>
            {props.children}
        </div>
    )
}

export const Paragraph100 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={classes.Paragraph100}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Paragraph200 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={[classes.Paragraph200]} >
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Paragraph350 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={classes.Paragraph350}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const Paragraph300 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={classes.Paragraph300} style={{ textAlign: props.left ? "left" : "" }}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const P16 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={classes.P16} style={{ textAlign: props.left ? "left" : "" }}>
                {props.children}
            </p>
        </Wrapper>
    )
};

export const P14 = props => {
    return (
        <Wrapper className={props.className}>
            <p className={classes.P14} style={{ textAlign: props.left ? "left" : "" }}>
                {props.children}
            </p>
        </Wrapper>
    )
};
