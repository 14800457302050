export const AGRANDAR_FUENTE = 'AGRANDAR_FUENTE';
export const REDUCIR_FUENTE = 'REDUCIR_FUENTE';
export const SET_VIEWPORT_SIZE = 'SET_VIEWPORT_SIZE';
export const DESTACAR_LINK = 'DESTACAR_LINK';

export const agrandarFuente = () => {
    return { type: AGRANDAR_FUENTE }
}

export const reducirFuente = () => {
    return { type: REDUCIR_FUENTE }
}
export const setViewportSize = (viewportSize) => {
    return { type: SET_VIEWPORT_SIZE, viewportSize: viewportSize }
}
export const destacarLinks = () => {


    const links = document.getElementsByTagName('a');
    for (let link of links) {
        console.dir(link);

        link.classList.add('link-accent')
    }

    setTimeout(() => {
        const links = document.getElementsByTagName('a');
        for (let link of links) {
            console.dir(link);

            link.classList.remove('link-accent')
        }
    }, 1100)


    return { type: DESTACAR_LINK, flag: true }
}