import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';

import classes from './ImageLoader.module.scss';

const ImageLoader = props => {
    const container = useRef();
    const [isLoading, setIsLoading] = useState(true);
    const [image, setImage] = useState(null)

    const classList = [classes.ImageLoader, props.className ? props.className : null, props.standar ? classes.Standar : null]

    useEffect(() => {
        setIsLoading(true);
        setImage(null);
        setImage(props.imagenPrincipal);

    }, [props.imagenPrincipal])

    let style = {}

    const calcularProporcion = (x, y) => {
        if (x === y) {
            return container.current.clientWidth
        } else {
            const factor = x + y;
            const height = container.current.clientWidth / factor;
            return height * y;
        }
    }

    if (props.ratioX && props.ratioY) {
        if (container.current) {
            const height = calcularProporcion(props.ratioX, props.ratioY);
            style = { height: height + 'px' }
        }
    }

    let imageStyle = {}

    if (props.objectFit) {
        imageStyle = { objectFit: props.objectFit }
    }

    // useEffect(() => {
    //     if(container.current){}
    // }, [container])


    return (
        <div ref={container} className={classList.join(' ')} style={style}>
            <img alt="imagen" draggable={false} className={classes.Image} style={imageStyle} src={image} onLoad={() => setIsLoading(false)} />
            {isLoading ? <Loader /> : null}
            {isLoading ? <div className={classes.Layout}> </div> : null}
        </div>
    )
};

ImageLoader.propTypes = {
    imagenPrincipal: PropTypes.string.isRequired,
    className: PropTypes.string,
    ratioX: PropTypes.number,
    ratioY: PropTypes.number,
    objectFit: PropTypes.oneOf(['none', 'contain', 'cover', 'fill', 'revert', 'scale-down']),
}


export default ImageLoader;