import { Icon } from '@material-ui/core';
import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import * as buscadorActions from './../../../store/actions/buscador';
import classes from './BuscadorInput.module.scss';
import ResultList from './ResultList/ResultList';

const BUSCADOR_INPUT_CHANGE = 'BUSCADOR_INPUT_CHANGE';
const LIMPIAR_INPUT = 'LIMPIAR_INPUT';


const inputReducer = (state, action) => {
    switch (action.type) {
        case BUSCADOR_INPUT_CHANGE:
            return {
                ...state,
                value: action.value
            }
        case LIMPIAR_INPUT:
            return {
                ...state,
                value: ''
            }
        default:
            return state
    }
}

const BuscadorInput = props => {
    const [inputState, dispatchInput] = useReducer(inputReducer, {
        value: '',
    })

    const [buscadorLoader, setBuscadorLoader] = useState(false);

    const dispatch = useDispatch();
    const noticiasEncontradas = useSelector(state => state.buscador.noticias)

    const buscarInputValue = () => {
        if (inputState.value.length > 5) {
            setBuscadorLoader(true)
            dispatch(buscadorActions.fetch_buscador_noticias(inputState.value))
        }
    }

    useEffect(() => {
        setBuscadorLoader(false);
    }, [noticiasEncontradas])




    const buscadorChangeHandle = (e) => {
        const text = e.target.value;
        dispatchInput({
            type: BUSCADOR_INPUT_CHANGE,
            value: text
        })
    }


    return (
        <>
            <div className={[classes.BuscadorInput, !props.buscadorStatus && classes.Hide].join(' ')}>
                <div className={classes.Buscador}>
                    <div className={classes.InputContainer}>
                        <Icon onClick={buscarInputValue}>
                            search
                        </Icon>
                        <input
                            type='text'
                            onChange={buscadorChangeHandle}
                            value={inputState.value}
                        ></input>

                    </div>

                </div>
                <ResultList buscadorStatus={buscadorLoader} noticiasEncontradas={noticiasEncontradas} cerrarBuscador={() => {
                    dispatchInput({
                        type: LIMPIAR_INPUT
                    })
                    dispatch(buscadorActions.limpiarBuscador())
                    dispatch(buscadorActions.desactivarVistaBuscador())
                }

                } />
            </div>

            <div className={[classes.BuscadorInputIntegrado].join(' ')}>
                <div className={classes.Buscador}>
                    <div className={classes.InputContainer}>
                        <Icon onClick={() => {
                            dispatch(buscadorActions.activarVistaBuscador())
                            buscarInputValue()
                        }}>
                            search
                        </Icon>
                        <input
                            type='text'
                            onChange={buscadorChangeHandle}
                            value={inputState.value}
                        ></input>

                    </div>


                </div>
                <div className={[classes.ResultListContainer, !props.buscadorStatus && classes.Disable].join(' ')}>

                    <ResultList buscadorStatus={buscadorLoader} noticiasEncontradas={noticiasEncontradas} cerrarBuscador={() => {
                        dispatchInput({
                            type: LIMPIAR_INPUT
                        })
                        dispatch(buscadorActions.limpiarBuscador())
                        dispatch(buscadorActions.desactivarVistaBuscador())
                    }

                    } />
                </div>
            </div>

        </>

    )
};

export default BuscadorInput;