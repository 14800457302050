import { Icon } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../../../constants';
import Loader from '../../../Loader/Loader';
import ResultElement from './ResultElement/ResultElement';
import PaginadorElementos from './../../../PaginadorElementos/PaginadorElementos';

import classes from './ResultList.module.scss';
import * as buscadorActions from './../../../../store/actions/buscador';

const ResultList = props => {

    const dispatch = useDispatch();

    const {
        coincidencias,
        paginas,
        paginaActual,
        busqueda
    } = useSelector(state => state.buscador)

    const pagepositionHandler = (page) => {
        dispatch(buscadorActions.irPagina(page - 1, busqueda))
    }

    return (
        props.buscadorStatus ?
            <div className={classes.ResultList}>
                <Loader />
            </div> :
            <div className={classes.ResultList}>
                <div className={classes.Header} onClick={props.cerrarBuscador}>
                    <p className={classes.ResultadoText}>Resultado:</p>
                    <Icon className={classes.CloseButton}>close</Icon>
                </div>
                {
                    coincidencias > 0 &&
                    props.noticiasEncontradas.map(n => <ResultElement key={n.id} idNoticia={n.id} titulo={n.Titular} bajada={n.Subtitulo} imagen={BASE_URL + n.Fotos[0].url} />)
                }
                {
                    coincidencias === 0 && busqueda && busqueda.trim().length > 0 ?
                        <div className={classes.SinCoincidencias}>
                            Sin Coincidencias
                        </div> : null
                }
                {coincidencias > 0 && <PaginadorElementos totalPaginas={paginas} pagePosition={paginaActual + 1} pagePositionHandler={pagepositionHandler} />}
            </div>


    )
};

export default ResultList;