import React from 'react'
import LocoSVG from './../../assets/images/SVG/LOGO_MUNICICIPALIDAD_2020_2.svg'
import classes from './Logo.module.scss';


const Logo = () => {
    return (
        <img src={LocoSVG} alt="municipalidad de cartagena" className={classes.Logo}></img>
    )
}
export default Logo;