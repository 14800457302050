import { BASE_URL } from "../../constants";
import Axios from "./../../axios/strapi";

export const FETCH_ORGANIGRAMA = 'FETCH_ORGANIGRAMA';


export const fetch_organigrama = () => {
    return async dispatch => {
        try {
            const organigrama = await Axios.get('/organigrama-municipal');
            const { Presentacion, imagen } = organigrama.data

            dispatch({
                type: FETCH_ORGANIGRAMA,
                presentacion: Presentacion,
                imagen: BASE_URL + imagen.url
            })
        } catch (err) {
            console.log('Error al intentar buscar Organigrama ', err);
        }
    }
}