import { Icon } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { Paragraph200, Paragraph300 } from '../Paragraph/Paragraph';
import ResolveLink from '../ResolveLink/ResolveLink';

import classes from './InformationBanner.module.scss';


const InformationBanner = props => {
    const { information, link } = useSelector(state => state.informationBanner.information)

    return (
        <ResolveLink link={link} className={classes.InformationBanner}>
            <div className={classes.InformationTitle}>
                <Icon className={classes.Icon}  >info</Icon>
                <Paragraph200>Información</Paragraph200>
            </div>
            <div className={classes.InformationText}>
                <Paragraph300>
                    {information}
                </Paragraph300>
                <Icon className={classes.Icon}>arrow_forward_ios</Icon>
            </div>
        </ResolveLink>
    )
};

export default InformationBanner;