
export const SET_SCROLL_STATE = 'SET_SCROLL_STATE';

export const setScrollState = (status) => {

    return async (dispatch) => {
        dispatch({
            type: SET_SCROLL_STATE,
            scrollStatus: status
        })
    }
}