import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../components/Modal/Modal';

import classes from './ModalViewer.module.scss';

import * as modalActions from './../../store/actions/modal';
import { BASE_URL } from '../../constants';
import HorizontalMoveHandler from '../../components/HorizontalMoveHandler/HorizontalMoveHandler';
import { Icon } from '@material-ui/core';

const ModalViewer = props => {

    const [numberElements, setNumberElements] = useState(1);
    const [viewerStyle, setViewerStyle] = useState({});
    const [viewPosition, setViewPosition] = useState(0)

    const dispatch = useDispatch();
    const { status, listaModal } = useSelector(state => state.modal);

    useEffect(() => {
        sessionStorage.setItem('showed', true);
    }, [status])

    useState(() => {
        setNumberElements(listaModal.length)
        setViewerStyle({
            width: `${listaModal.length * 100}%`
        })
    }, [listaModal, viewPosition])

    const nextHandler = () => {
        if (numberElements - 1 >= viewPosition + 1) {
            setViewerStyle({ ...viewerStyle, transform: `translateX(-${(viewPosition + 1) * (100 / numberElements)}%)` })
            setViewPosition(viewPosition + 1);
        }
    }

    const prevHandler = () => {
        if (0 <= viewPosition - 1) {
            setViewerStyle({ ...viewerStyle, transform: `translateX(-${(viewPosition - 1) * (100 / numberElements)}%)` })
            setViewPosition(viewPosition - 1);
        }
    }

    const setPosition = (p) => {
        setViewerStyle({ ...viewerStyle, transform: `translateX(-${(p) * (100 / numberElements)}%)` })
        setViewPosition(p);
    }

    const indicators = []
    for (let i = 0; i < numberElements; i++) {
        indicators.push(<span id={i} onClick={() => setPosition(i)} className={[classes.Indicator, i === viewPosition ? classes.Selected : null].join(' ')}></span>)
    }


    return (
        <div className={classes.ModalViewer}>
            <div className={classes.Overlay} onClick={() => dispatch(modalActions.cerrarModal())}></div>
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                <div className={classes.ModalContainer} >

                    <div className={classes.ViewerContainer}>
                        <HorizontalMoveHandler onLeft={nextHandler} onRight={prevHandler}>
                            <div className={classes.Viewer} style={viewerStyle}>
                                {listaModal.map(modal => {
                                    if (modal.linkbButton == null) {
                                        return <Modal key={modal.IdentificadorModal} salir={() => dispatch(modalActions.cerrarModal())} titulo={modal.Titulo} imagen={BASE_URL + modal.imagen.url} />;
                                    }
                                    else {
                                        return <Modal key={modal.IdentificadorModal} salir={() => dispatch(modalActions.cerrarModal())} titulo={modal.Titulo} label={modal.linkbButton.label} link={modal.linkbButton.Link} imagen={BASE_URL + modal.imagen.url} />;
                                    }
                                })}
                            </div>
                        </HorizontalMoveHandler>
                    </div>
                    <div className={classes.IndicatorContainer}>
                        {indicators.map(i => i)}
                    </div>

                </div>
            </div>

        </div>
    )
};

export default ModalViewer;