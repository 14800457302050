import Axios from "./../../axios/strapi";

export const FETCH_SOCIEDAD_CIVIL = 'FETCH_SOCIEDAD_CIVIL'

export const fetch_consejo_sociedad_civil = () => {
    return async dispatch => {
        try {
            const consejo = await Axios.get('/sociedad-civils');

            dispatch({
                type: FETCH_SOCIEDAD_CIVIL,
                presentacion: consejo.data[0].presentacion,
                consejeros: consejo.data[0].concejero_sociedad_civils.filter(c => c.estado).sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1
                    }
                    if (a.orden < b.orden) {
                        return -1
                    }
                    return 0;

                })
            })
        } catch (e) {
            console.log('error al buscar consejo municipal ', e)
        }

    }
}