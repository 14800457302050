import Axios from './../../axios/strapi';

export const FETCH_SOCIAL = 'FETCH_SOCIAL';

export const fetch_social = () => {
    return async (dispatch) => {
        try {
            const response = await Axios.get('redes-sociales');
            dispatch({
                type: FETCH_SOCIAL,
                social: response.data
            })



        } catch {

        }
    }
}