import { FETCH_DATA_MODAL } from "../actions/modal";
import { CERRAR_MODAL } from "../actions/videoModal";

const initState = {
    status: false,
    listaModal: []
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_DATA_MODAL:
            return {
                ...state,
                status: action.modal.status,
                listaModal: action.modal.modals
            }
        case CERRAR_MODAL:
            return {
                ...state,
                status: false
            }
        default:
            return state;
    }
}