import { ABRIR_MODAL, CERRAR_MODAL, SET_VIDEO } from "../actions/videoModal";

const initialState = {
    opened: false,
    videoUrl: null
}

export default (state = initialState, action) => {
    switch (action.type) {
        case SET_VIDEO:
            return {
                ...state,
                videoUrl: action.videoUrl
            }
        case ABRIR_MODAL:
            return {
                ...state,
                opened: true,
            }
        case CERRAR_MODAL:
            return {
                ...state,
                opened: false,
            }
        default:
            return state;
    }
}