import { FETCH_LINK_INTERES } from "../actions/linkInteres";

const initialState = {
    links: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_LINK_INTERES:
            return {
                links: action.links
            }

        default:
            return state;
    }
}