import React from 'react';
import PropTypes from "prop-types";

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

import { Title100 } from './../Titles/Titles';

import classes from './RichTextReader.module.scss';
import { P16 } from '../Paragraph/Paragraph';
import { BASE_URL } from '../../constants';

const RichTextReader = props => {

    const getValue = (e) => {
        if (!e) return ''
        if (e.type === 'text') {
            return e.value
        }

        if (e.children) {
            return getValue(e.children[0])
        }

    }

    const renderers = {
        table: (a) => {
            return <table className={classes.Table} style={{
                textAlign: a.columnAlignment
            }}>{a.children}</table>;
        },
        tableHead: (a) => {
            return <thead className={classes.TableHeader}>{a.children}</thead>;
        },
        tableBody: (a) => {
            return <tbody className={classes.TableBody}>{a.children}</tbody>;
        },
        tableCell: (a) => {
            return <td><P16>{a.children}</P16></td>;
        },
        paragraph: (a) => {
            console.log(a)
            return <P16 className={classes.ParradoPersonalizado}>{a.children}</P16>
        },
        heading: ({ node }) => {
            return node.children.map((t, i) => {
                return <Title100 key={i} style={{ textAlign: 'left' }}>{t.value}</Title100>
            })
        },
        listItem: (a) => {
            return <li className={classes.ListItem}><P16>{getValue(a.node)}</P16></li>
        },
        list: (a) => {
            return <ul className={classes.List}>{a.children}</ul>
        },
        link: (a) => {
            return <a href={a.href} className={classes.Link}>{a.children}</a>
        },
        text: (a) => {

            if (a.children.match(/\\n/)) {
                const saltos = a.children.split(/\\n/)
                return saltos.map((e, i) => {

                    if (saltos.length - 1 === i) {
                        return e
                    }
                    return <>{e}<br /></>
                })
            } else {
                return a.children
            }
        },
        image: (a) => {
            console.log(a);
            return <img className={classes.Image} alt={a.alt} src={BASE_URL + a.src} />
        }
    }

    const classList = [classes.RichTextReader];

    if (props.className) {
        classList.push(props.className);
    }


    return (
        <div className={classList.join(' ')}>
            {props.children}
            <ReactMarkdown plugins={[gfm]} unwrapDisallowed renderers={renderers} children={props.bodyText} />
        </div>
    )
};

RichTextReader.propTypes = {
    bodyText: PropTypes.string.isRequired
}

export default RichTextReader;