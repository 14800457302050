import React from 'react';
import { Paragraph100 } from '../../../../components/Paragraph/Paragraph';

import classes from './EntradaDeTexto.module.scss';

const EntradaDeTexto = props => {
    return (
        <div className={props.className}>
            {props.textos.map((entrada, i) => <Paragraph100 className={classes.Texto} key={i}>{entrada}</Paragraph100>)}
        </div>
    )
};

export default EntradaDeTexto;