import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import Axios from '../../axios/strapi';
import { P16 } from '../../components/Paragraph/Paragraph';
import RichTextReader from '../../components/RichTextReader/RichTextReader';
import { BASE_URL } from '../../constants';
import { T24 } from './../../components/Titles/Titles';
import * as noticiasActions from './../../store/actions/noticias';

import classes from './News.module.scss';

const News = props => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { location } = props;
    const id = new URLSearchParams(location.search).get("id");


    useEffect(() => {
        dispatch(noticiasActions.aumentar_contador(new URLSearchParams(location.search).get("id")))
        if (id) {
            setIdNoticia(id)
        }
    }, [dispatch, id, location.search])


    window.scroll({
        top: 0,
        behavior: 'smooth'
    })





    const [noticia, setNoticia] = useState();
    const [idNoticia, setIdNoticia] = useState(id);


    const getFechaFormateada = fechaString => {
        let fecha = new Date(fechaString);
        let fechaFormateada = fecha.getDate() < 10 ? `0${fecha.getDate()}/` : `${fecha.getDate()}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getMonth() < 9 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getFullYear()} - `;
        fechaFormateada = `${fechaFormateada}${fecha.getHours() < 10 ? `0${fecha.getHours()}` : fecha.getHours()}:`;
        fechaFormateada = `${fechaFormateada}${fecha.getMinutes() < 10 ? `0${fecha.getMinutes()}` : fecha.getMinutes()}h`;
        return fechaFormateada;
    }

    useEffect(() => {
        Axios.get(`noticias/${idNoticia}`)
            .then(response => response.data)
            .then(setNoticia)

    }, [location.search, idNoticia]);

    useEffect(() => {
        if (noticia) {
            window.setTituloSitio(`${noticia.Titular} | Municipalidad de Cartagena`);
        }
    }, [noticia]);

    const imageMarkdownDomainInjector = (text, domain) => {
        const path = /\!\[(.)+\]\((\/\w+)+\.\w+\)/
        if (text.match(path)) {
            const p = text.match(/(\/\w+)+\.\w+\)$/);
            if (p) {
                text = text.replace(p[0], domain + p[0]);
            }
        }
        return text;
    }

    return noticia ? (
        <div className={classes.noticia} >

            <div className={classes.btnVolver} onClick={() => history.goBack()} >Volver</div>

            <div style={{
                backgroundImage: `url(${Axios.defaults.baseURL}${noticia.Fotos[0].url})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                position: "relative"
            }}
                className={classes.HeaderImage}
            >
                <div className={classes.titular}>
                    <div>
                        {noticia.Titular}
                    </div>
                </div>
            </div>
            <div>
                <T24 className={classes.subtitulo}>{noticia.Subtitulo}</ T24>
                {/* <P16 className={classes.cuerpo}> */}
                <RichTextReader bodyText={noticia.Cuerpo} className={classes.cuerpo} >

                </RichTextReader>
                {/* {noticia.Cuerpo.split("\n").map(
                        parrafo => {
                            parrafo = imageMarkdownDomainInjector(parrafo, BASE_URL);
                            return <ReactMarkdown>
                                {parrafo}
                            </ReactMarkdown>
                        }
                    )} */}
                {/* </P16> */}
            </div>
            <div className={classes.extraInfo}>
                <div className={classes.fechaCreacion}>{getFechaFormateada(noticia.created_at)}</div>
                <div className={classes.categoria}>{noticia.Categoria}</div>
            </div>
        </div>
    )
        : ""
};

export default News;