import { CREAR_PAGINADOR_REVISTAS, FETCH_REVISTA, FETCH_REVISTA_PAGINADA, IR_PAGINA_REVISTA } from "../actions/revistas";
import { ABRIR_MODAL, CERRAR_MODAL } from "../actions/videoModal";

const initState = {
    items: [],
    itemNumber: 0,
    paginador: null,
    revistaPaginada: null
}



export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_REVISTA:
            return {
                items: action.data,
                itemNumber: action.elements
            }
        case CREAR_PAGINADOR_REVISTAS:
            return {
                ...state,
                paginador: action.paginador
            }

        case IR_PAGINA_REVISTA: {
            return {
                ...state,
                paginador: {
                    ...state.paginador,
                    paginaActual: action.pagina
                }
            }
        }
        case FETCH_REVISTA_PAGINADA:
            return {
                ...state,
                revistaPaginada: action.articulos
            }
        default:
            return state;
    }
}