import React from 'react';

import classes from './ConocerMas.module.scss';

import { TitlePrincipal } from './../../../components/Titles/Titles';
import Places from './Places/Places';
import { useSelector } from 'react-redux';

const ConocerMas = () => {
    const placesList = useSelector(state => state.principal.places);
    const texts = useSelector(state => state.principal.texts);




    return (
        <div className={classes.ConocerMas}>
            <TitlePrincipal>{texts && texts.tituloConocerMasDeCartagena ? texts.tituloConocerMasDeCartagena : 'Conoce más de Cartagena'}</TitlePrincipal>
            {placesList ? <Places placesList={placesList} /> : null}
        </div>
    )
};

export default ConocerMas;