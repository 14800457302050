import { SvgIcon } from '@material-ui/core';
import React from 'react';

import classes from './RedSocialIcon.module.scss';

import instagram from './../../../assets/images/instagram.svg';
import twitter from './../../../assets/images/twitter.svg';
import Facebook from './../../../assets/images/facebook.svg';
import Youtube from './../../../assets/images/youtube.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faFacebookMessenger, faGoogle, faInstagram, faPinterest, faSkype, faSnapchat, faTiktok, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';

const RedSocialIcon = props => {
    let img = null;
    if (props.redSocial === 'Instagram') {
        img = <img src={instagram} alt="instagram" />
    }
    if (props.redSocial === 'Twitter') {
        img = <img src={twitter} alt="twitter" />
    }
    if (props.redSocial === 'Facebook') {
        img = <img src={Facebook} alt="Facebook" />
    }
    if (props.redSocial === 'Youtube') {
        img = <img src={Youtube} alt="Youtube" />
    }

    let icon;
    switch (props.redSocial) {
        case 'Facebook':
            icon = faFacebook;
            break;
        case 'Twitter':
            icon = faTwitter;
            break;
        case 'Instagram':
            icon = faInstagram;
            break;
        case 'Youtube':
            icon = faYoutube;
            break;
        case 'Google':
            icon = faGoogle;
            break;
        case 'Tiktok':
            icon = faTiktok;
            break;
        case 'Pinterest':
            icon = faPinterest;
            break;
        case 'Messenger':
            icon = faFacebookMessenger;
            break;
        case 'Skype':
            icon = faSkype;
            break;
        case 'Snapchat':
            icon = faSnapchat;
            break;
        default:
            icon = '';
    }

    return (
        <a
            href={props.link}
            className={classes.RedSocial}
            target="_blank"
            rel="noopener noreferrer"
            categoria="redes-sociales"
            etiqueta={props.redSocial}
        >
            {/* {img} */}
            <FontAwesomeIcon icon={icon} />
        </a>
    )
};

export default RedSocialIcon;