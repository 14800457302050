import React, { useEffect, useState } from 'react';
import Axios from '../../axios/strapi';
import Loader from '../../components/Loader/Loader';
import { Paragraph100 } from '../../components/Paragraph/Paragraph';
import { TitlePrincipal } from '../../components/Titles/Titles';
import UserPresentation from '../../components/UserPresentation/UserPresentation';
import ReactMarkdown from 'react-markdown';
import { BASE_URL } from '../../constants';

import classes from './Alcalde.module.scss';

const Alcalde = props => {


    window.scroll({
        top: 0,
        behavior: 'smooth'
    })


    const [alcalde, setAlcalde] = useState();
    useEffect(() => {
        Axios.get('alcalde')
            .then(response => setAlcalde(response.data))
    }, []);


    return (
        alcalde && alcalde.Foto ? <div className={classes.Alcalde}>
            <TitlePrincipal style={{ textAlign: 'justify' }}>Alcalde</TitlePrincipal>
            <Paragraph100 className={classes.Justify}>
                {alcalde.ResumenBiografico.split("\n").map(parrafo => (
                    <ReactMarkdown>
                        {parrafo}
                    </ReactMarkdown>
                ))}
            </Paragraph100>

            <section className={classes.DatosAlcalde}>


                <UserPresentation
                    imagen={BASE_URL + alcalde.Foto.url}
                    nombre={alcalde.Nombre}
                    cargo="ALCALDE"
                />

                <Paragraph100 className={classes.Parrafo}>
                    {alcalde.ResumenProfesional.split("\n").map(parrafo => (
                        <ReactMarkdown>
                            {parrafo}
                        </ReactMarkdown>
                    ))}
                </Paragraph100>
            </section>
        </div> : <Loader />
    );
}
export default Alcalde;