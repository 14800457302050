import React from 'react';
import { Title300 } from '../../Titles/Titles';

import classes from './Contacto.module.scss';

const Contacto = props => {
    return (
        <a href={`tel:${props.contacto}`} className={classes.Contacto} >
            <p className={classes.Value}>{props.nombreContacto}</p>
            <p className={classes.Title}>{props.contacto}</p>
        </a>
    )
};

export default Contacto;