import { FETCH_SOCIEDAD_CIVIL } from "../actions/consejoSociedadCivil";

const initState = {
    presentacion: '',
    consejeros: []
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_SOCIEDAD_CIVIL:
            return {
                presentacion: action.presentacion,
                consejeros: action.consejeros
            }

        default:
            return state;
    }
} 