import { FETCH_SOCIAL } from "../actions/social";

const initState = {
    socialList: []
}

export default (state = initState, action) => {
    switch(action.type){
        case FETCH_SOCIAL: 
            return {
                socialList: action.social
            }
        default:
            return state;
    }
}