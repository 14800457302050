import React, { useEffect, useState } from 'react';
import Portada from '../../components/Portada/Portada';

import classes from './DireccionTemplate.module.scss';
import { useSelector } from 'react-redux';
import Loader from '../../components/Loader/Loader';
import { BASE_URL } from '../../constants';
import RichTextReader from '../../components/RichTextReader/RichTextReader';
import { Title100 } from '../../components/Titles/Titles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { P16 } from '../../components/Paragraph/Paragraph';

const DireccionTemplate = props => {

    const [direccion, setDireccion] = useState(null);


    const direcciones = useSelector(state => state.direcciones.direcciones_municipales);

    window.scroll({
        top: 0,
        behavior: 'smooth'
    })



    useEffect(() => {
        const id = props.match.params.id;
        if (direcciones && direcciones.length > 0) {
            const d = direcciones.find(d => d.id.toString() === id.toString());
            console.log(d);
            setDireccion(d);

        }
    }, [direcciones, props.match.params.id])

    let render = (
        <div className={classes.Loader}>
            <Loader />
        </div>
    )
    if (direccion) {
        render = (
            <div className={classes.DireccionTempalte}>
                {/* <Portada titulo={direccion.titulo} imagenPrincipal={BASE_URL + direccion.imagen_portada.url} color={'color={'#97C150'}'} /> */}
                <div className={classes.DireccionContent}>
                    <section className={classes.TextContainer}>
                        <RichTextReader bodyText={direccion.entrada} className={classes.Rich} >
                            <div className={classes.Title} style={{ backgroundColor: '#97C150' }}>
                                <Title100>
                                    {direccion.titulo}
                                </Title100>
                            </div>
                            { direccion.imagen_portada 
                            ? <img className={classes.ImagenPrincipal} src={BASE_URL + direccion.imagen_portada.url} />
                            : '' }
                        </RichTextReader>
                        <div className={classes.Descargas}>
                            {
                                direccion.Adjuntar_Archivo ? direccion.Adjuntar_Archivo.map(file => {
                                    console.log(file);
                                    return <P16>
                                        <a href={BASE_URL + file.url} download={file.name} target="_blank" rel="noopener noreferrer">
                                            <FontAwesomeIcon icon={faDownload}></FontAwesomeIcon> {file.name}
                                        </a>
                                    </P16>
                                }) : null
                            }
                        </div>
                    </section>
                </div>
            </div>
        )
    }

    return (
        <>
            {render}
        </>
    )
};

export default DireccionTemplate;