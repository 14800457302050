import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LinkPrincipal from './LinkPrincipal/LinkPrincipal';
import classes from './LinksGob.module.scss';

import * as linksGobiernoActions from './../../../store/actions/linkGobierno';

const LinkGob = props => {

    const linksGobierno = useSelector(state => state.principal.linksGobierno);
    // const dispatch = useDispatch();

    // useEffect(() => {
    //     dispatch(linksGobiernoActions.fetchLinkGobierno());
    // }, [dispatch]);

    let linkList = null
    
    if(linksGobierno){
        linkList = linksGobierno.map(link => <LinkPrincipal
            key={link.id}
            title={link.titulo}
            label={link.label}
            icon={link.iconName}
            redirect={link.link}
        />)

    }


    return (
        <div className={classes.LinksGob}>
            {linkList}
        </div>
    )
};

export default LinkGob;
