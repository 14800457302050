import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as globalStyleActions from '../../store/actions/globalStyles';


const useViewportSize = props => {

    let { innerWidth } = window;
    const [clientWidth, setClientWidth] = useState(innerWidth);

    const getViewportSize = () => {
        if (clientWidth < 576) {
            return 'xs';
        }
        if (clientWidth >= 576 && clientWidth < 768) {
            return 'sm';
        }
        if (clientWidth >= 768 && clientWidth < 992) {
            return 'md';
        }
        if (clientWidth >= 992 && clientWidth < 1200) {
            return 'lg';
        }
        if (clientWidth >= 1200) {
            return 'xl';
        }
    }
    
    
    
    const [viewportSize, setViewportSize] = useState(getViewportSize())
 

    const setViewport = () => {
        setClientWidth(window.innerWidth);
        setViewportSize(getViewportSize());
    }

    window.removeEventListener('resize', setViewport)

    window.addEventListener('resize', setViewport)

    


    return viewportSize;
};

export default useViewportSize;