import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ImageLoader from '../ImageLoader/ImageLoader';
import PropTypes from 'prop-types';
import { Paragraph100 } from '../Paragraph/Paragraph';
import * as videoModalActions from './../../store/actions/videoModal';

import classes from './MultimediaItem.module.scss';
import { useDispatch } from 'react-redux';
import { BASE_URL } from '../../constants';

const Item = props => {

    const { label } = props;
    
    // useLayoutEffect(() => {
    //     function updateSize() {
    //         if (ContainerRef.current.clientWidth > 0) {
    //             console.dir(ContainerRef.current.clientWidth)
    //             setStyle({ height: (ContainerRef.current.offsetWidth) + 'px' })
    //         }
    //     }
    //     window.addEventListener('resize', updateSize);
    //     updateSize();
    //     return () => window.removeEventListener('resize', updateSize);
    // }, []);

    if (props.link) {
        return (
            <a draggable={false} className={classes.Articulo} href={props.action} categoria="revistas-y-videos" etiqueta={label}>
                {props.children}
            </a>
        )
    } else {
        return (
            <div className={classes.Articulo} onClick={props.action} >
                {props.children}
            </div>
        )
    }


}




const MultimediaItem = props => {


    const dispatch = useDispatch();

    const openModalHandler = () => {
        dispatch(videoModalActions.abrirModal());
        dispatch(videoModalActions.setVideo(BASE_URL + props.dinamic.VideoMultimedia.url));
    }

    let action = null
    let link = false;


    if (props.dinamic && props.dinamic.Link) {
        action = props.dinamic.Link;
        link = true;
    }

    if (props.dinamic && props.dinamic.VideoMultimedia) {
        action = openModalHandler
    }

    return (
        <div className={classes.Container}>
            <Item action={action} link={link} label={props.label}>
                <ImageLoader className={classes.Img} imagenPrincipal={props.imagen} />
                <p className={classes.Label} >{props.label}</p>
            </Item>
        </div>
    )

};

MultimediaItem.propTypes = {
    imagen: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    dinamic: PropTypes.object,
}

export default MultimediaItem;