import { BASE_URL } from "../../constants";
import Axios from "./../../axios/strapi";

export const FETCH_CARTAGENA = 'FETCH_CARTAGENA';

export const fetch_cartagena = () => {
    return async (dispatch) => {
        try {
            const cartagena = await Axios.get('seccion-cartagena');

            const dataCartagena = cartagena.data;
            const galeria = Array.from(dataCartagena.galeria, imagen => BASE_URL + imagen.url)
            const primeraEntrada = (Array.from(dataCartagena.primera_entrada.split('</p>'), entrada => entrada.replace('<p>', '').trim())).filter(text => text.length > 0);
            const segundaEntrada = (Array.from(dataCartagena.segunda_entrada.split('</p>'), entrada => entrada.replace('<p>', '').trim())).filter(text => text.length > 0);

            dispatch({
                type: FETCH_CARTAGENA,
                galeria: galeria,
                imagenMapa: BASE_URL + dataCartagena.imagen_mapa.url,
                imagenPrincipal: BASE_URL + dataCartagena.imagen_principal.url,
                primeraEntrada: primeraEntrada,
                segundaEntrada: segundaEntrada,
            })


        } catch (e) {

        }


    }
}