import Axios from "./../../axios/strapi";

export const FETCH_CONSEJO = 'FETCH_CONSEJO'

export const fetch_consejo = () => {
    return async dispatch => {
        try {
            const consejo = await Axios.get('/consejo-municipal');
            console.log(consejo);

            dispatch({
                type: FETCH_CONSEJO,
                titulo: consejo.data.titulo,
                presentacion: consejo.data.presentacion,
                consejeros: consejo.data.consejero_municipals.filter(c => c.estado).sort((a, b) => {
                    if (a.orden > b.orden) {
                        return 1
                    }
                    if (a.orden < b.orden) {
                        return -1
                    }
                    return 0;

                })
            })
        } catch (e) {
            console.log('error al buscar consejo municipal ', e)
        }

    }
}