import React from 'react';
import PropsType from 'prop-types'

import classes from './CardLink.module.scss';

import { Paragraph200, Paragraph300 } from './../Paragraph/Paragraph'
import Button from '../Button/Button';

import ex from './../../assets/images/box.png';
import ImageLoader from '../ImageLoader/ImageLoader';
import { Title500 } from '../Titles/Titles';
import { NavLink } from 'react-router-dom';

const CardLink = props => {
    let enlace;
    if (props.linkButton.match(/(http|https)/)) {
        enlace = (
            <a
                className={classes.Button}
                href={props.linkButton}
                target="_blank"
                rel="noopener noreferrer"
                categoria="totem"
                etiqueta={props.titulo.toUpperCase()}
                >
                <Title500>{props.buttonText}</Title500>
            </a>
        )
    } else {
        enlace = (
            <NavLink className={classes.Button} to={props.linkButton} 
            categoria="totem"
            etiqueta={props.titulo.toUpperCase()}>
                <Title500>{props.buttonText}</Title500>
            </NavLink>
        )
    }

    return (
        <div className={classes.CardLink}>

            <div className={classes.Container}>
                <ImageLoader imagenPrincipal={props.imagen} className={classes.RoundImage} />
                <div className={classes.Info}>
                    <p className={classes.CardTitle}>{props.titulo.toUpperCase()}</p>
                    <p className={classes.Body}>{props.subtitulo}</p>
                    {enlace}
                </div>
            </div>
        </div >
    )
};

CardLink.propTypes = {
    titulo: PropsType.string.isRequired,
    subtitulo: PropsType.string.isRequired,
    buttonText: PropsType.string.isRequired,
    linkButton: PropsType.string.isRequired,
    imagen: PropsType.string.isRequired
}

export default CardLink;