import Axios from './../../axios/strapi';

export const FETCH_TIPOS_DOCUMENTOS = 'FETCH_TIPOS_DOCUMENTOS';

export const fetchTiposDocumentos = () => {
    return async (dispatch) => {
        try {

            const tiposDeDocumentos = await Axios.get('/tipos-de-documentos-de-interes');

            let documentosInteres = []
            for (let tipo of tiposDeDocumentos.data) {
                const documentosInteresTipo = await Axios.get('documentos-de-interes',
                    {
                        params: {
                            tipos_de_documentos_de_intere: tipo.id,
                            _sort: 'updated_at:DESC'
                        }
                    }
                );
                documentosInteres.push({
                    ...tipo,
                    documentos: [...documentosInteresTipo.data]
                });
            };

            dispatch({ type: FETCH_TIPOS_DOCUMENTOS, data: documentosInteres, elements: documentosInteres.length })
        } catch (err) {
            throw err;
        }
    }
}
