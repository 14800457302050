import { Icon } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HorizontalMoveHandler from '../../../../components/HorizontalMoveHandler/HorizontalMoveHandler';
import Loader from '../../../../components/Loader/Loader';
import MultimediaItem from '../../../../components/MultimediaItem/MultimediaItem';
import { BASE_URL } from '../../../../constants';
import useViewportSize from '../../../../hooks/useViewportSize/useViewportSize';

import classes from './Viewer.module.scss';

const Viewer = props => {
    const [actualPosition, setActualPosition] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(1);
    const [elementos, setElementos] = useState(0);
    const [arrowStatus, setArrowStatus] = useState(true);

    const viewportSize = useViewportSize();

    const setStyleHandler = useCallback(() => {
        return {
            transform: `translateX(0%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        }
    }, [itemsPerView, elementos])

    const [viewPosition, setViewPosition] = useState(setStyleHandler())

    useEffect(() => {
        if (props.articulos) {
            setElementos(props.articulos.itemsNumber);
            setViewPosition(setStyleHandler());
        }
    }, [props.articulos, setStyleHandler, actualPosition])

    const containerRef = useRef();

    const animationTransitionHandler = () => {
        if (containerRef && containerRef.current) {
            containerRef.current.style.transition = null
            containerRef.current.ontransitionend = function (event) {
            }
            containerRef.current.style.transition = 'all .5s';
        }
    }

    const calculateViewerPosition = () => {
        const movement = 100 / elementos;
        animationTransitionHandler();
        setActualPosition(0)
        setViewPosition({
            transform: `translateX(-${movement * actualPosition}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }


    useMemo(() => {
        if (elementos) {
            switch (viewportSize) {
                case 'xs':
                    setItemsPerView(1);
                    break;
                case 'sm':
                    if (elementos >= 2) {
                        setItemsPerView(2);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'md':
                    if (elementos >= 3) {
                        setItemsPerView(3);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'lg':
                    if (elementos >= 4) {
                        setItemsPerView(4);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'xl':
                    if (elementos >= 5) {
                        setItemsPerView(5);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                default:
            }
        }
        calculateViewerPosition()

    }, [elementos, viewportSize])

    useEffect(() => {
        if (itemsPerView === elementos) {
            setArrowStatus(false);
        } else {
            setArrowStatus(true);
        }
    }, [itemsPerView, elementos])

    const nextView = () => {
        if ((elementos - itemsPerView) > (actualPosition)) {
            setActualPosition(actualPosition + 1);
        } else {
            setActualPosition(0);
        }

    }

    const prevView = () => {
        if (actualPosition === 0 && itemsPerView < elementos) {
            setActualPosition(elementos - itemsPerView);
        } else {
            setActualPosition(actualPosition - 1)
        }
    }





    useEffect(() => {
        animationTransitionHandler();
        const movement = 100 / elementos;
        setViewPosition({
            transform: `translateX(-${movement * (actualPosition)}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }, [actualPosition, itemsPerView, elementos])



    let render = <Loader />
    if (props.articulos && props.articulos.itemsNumber > 0) {
        render = (
            <div className={classes.Viewer}>
                <Icon className={[classes.Arrow, !arrowStatus ? classes.Disabled : null].join(' ')} onClick={prevView}>keyboard_arrow_left</Icon>
                <HorizontalMoveHandler onLeft={nextView} onRight={prevView}>

                    <div className={classes.Container}>
                        <div ref={containerRef} className={classes.ContainerView} style={viewPosition}>
                            {props.articulos.items.map(articulo => <MultimediaItem key={articulo.id} label={articulo.Label} imagen={BASE_URL + articulo.ImagenPrincipal.url} dinamic={articulo.dinamic[0]} />)}
                        </div>
                    </div>
                </HorizontalMoveHandler>
                <Icon className={[classes.Arrow, !arrowStatus ? classes.Disabled : null].join(' ')} onClick={nextView}>keyboard_arrow_right</Icon>
            </div>
        )
    }


    return render;
};

export default Viewer;