import React from 'react';

import classes from './Mirador.module.scss';
import { Title100 } from './../../../components/Titles/Titles';
import ImageLoader from '../../../components/ImageLoader/ImageLoader';

const Mirador = props => {

    // const styles = {
    //     backgroundImage: `url(${props.imagenPrincipal}) `,
    //     'backgroundPosition': 'center',
    //     'backgroundSize': 'cover',
    //     'backgroundRepeat': 'no-repeat'
    // }

    return (
        <div className={classes.Mirador}>
            <ImageLoader className={classes.Image} imagenPrincipal={props.imagenPrincipal} />
            <div className={classes.Title} style={{ backgroundColor: props.color }}>
                <Title100>
                    {props.titulo}
                </Title100>
            </div>
        </div>
    )
};

export default Mirador;