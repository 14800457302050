import React from 'react';
import ImageLoader from '../../../../components/ImageLoader/ImageLoader';

import classes from './GaleriaCartagena.module.scss';

const GaleriaCartagena = props => {
    return (
        <div className={classes.GaleriaCartagena}>
            {
                props.galeria.map((imagen, i ) => 
                    <div key={i} className={classes.Image}>
                        <ImageLoader imagenPrincipal={imagen} ></ImageLoader>
                    </div>
                )
            }
        </div>
    )
};

export default GaleriaCartagena;