import { FETCH_ORGANIGRAMA } from "../actions/organigramaMunicipal"

const initState = {
    presentacion: '',
    imagen: ''
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_ORGANIGRAMA:
            return {
                presentacion: action.presentacion,
                imagen: action.imagen
            }
        default:
            return state;
    }
}