import {
    faFacebook,
    faInstagram,
    faTiktok,
    faGoogle,
    faYoutube,
    faFacebookMessenger,
    faPinterest,
    faSkype,
    faSnapchat,
    faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import classes from './RedSocialLink.module.scss';

const RedSocialLink = props => {

    let icon;
    switch (props.redSocial) {
        case 'Facebook':
            icon = faFacebook;
            break;
        case 'Twitter':
            icon = faTwitter;
            break;
        case 'Instagram':
            icon = faInstagram;
            break;
        case 'Youtube':
            icon = faYoutube;
            break;
        case 'Google':
            icon = faGoogle;
            break;
        case 'Tiktok':
            icon = faTiktok;
            break;
        case 'Pinterest':
            icon = faPinterest;
            break;
        case 'Messenger':
            icon = faFacebookMessenger;
            break;
        case 'Skype':
            icon = faSkype;
            break;
        case 'Snapchat':
            icon = faSnapchat;
            break;
        default:
            icon = '';
    }


    return (
        <a className={classes.Link} href={props.link} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={icon} />
        </a>
    )
};

export default RedSocialLink;