import React from 'react';
import Button from '../Button/Button';
import ImageLoader from '../ImageLoader/ImageLoader';
import ResolveLink from '../ResolveLink/ResolveLink';
import { T30 } from '../Titles/Titles';
import { Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import * as modalActions from './../../store/actions/modal';

import classes from './Modal.module.scss';

const Modal = props => {
    const dispatch = useDispatch();
    const { link, label } = props;

    return (
        <div className={classes.Modal}>
            <Icon className={classes.Close} onClick={() => dispatch(modalActions.cerrarModal())} >close</Icon>
            <T30 className={classes.Left}>{props.titulo}</T30>
            <div className={classes.ImageConainer}>
                <img className={classes.ImagenInformativa} src={props.imagen}>

                </img>
            </div>
            {/* <ImageLoader imagenPrincipal={props.imagen} standar /> */}
            <div className={classes.ButtonContainer} style={{ marginTop: '20px' }}>
                {link ? <ResolveLink link={link}>
                    <Button>{label}</Button>
                </ResolveLink>
                    : ""
                }
            </div>
        </div>
    )
};

export default Modal;