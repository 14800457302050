import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MultimediaItem from '../../components/MultimediaItem/MultimediaItem';
import { TitlePrincipal } from '../../components/Titles/Titles';

import classes from './Revista.module.scss';
import * as revistaActions from './../../store/actions/revistas';
import { BASE_URL } from '../../constants';
import PaginadorElementos from './../../components/PaginadorElementos/PaginadorElementos';

const Revista = props => {
    const dispatch = useDispatch();

    const articulos = useSelector(state => state.revista.revistaPaginada)
    const paginador = useSelector(state => state.revista.paginador)

    window.setTituloSitio(`Revista`);


    window.scroll({
        top: 0,
        behavior: 'smooth'
    })

    useEffect(() => {
        dispatch(revistaActions.crear_paginador())
        dispatch(revistaActions.fetchRevistaPaginada(1))
    }, [dispatch]);


    const paginadorHandler = (pagina) => {
        dispatch(revistaActions.ir_pagina(pagina));
        dispatch(revistaActions.fetchRevistaPaginada(pagina));
    }



    return (
        <div className={classes.Revista}>
            <div className={classes.TituloPrincipal}>
                <TitlePrincipal > Revistas y Vídeos</TitlePrincipal>
            </div>
            <section className={classes.Articulos}>
                {
                    articulos && articulos.length > 0 ?
                        articulos.map(item => <MultimediaItem key={item.id} imagen={BASE_URL + item.ImagenPrincipal.url} label={item.Label} dinamic={item.dinamic[0]}></MultimediaItem>)
                        : <p>Sin items</p>
                }
            </section>
            {paginador && <PaginadorElementos totalPaginas={paginador.cantidadDePaginas} pagePosition={paginador.paginaActual} pagePositionHandler={paginadorHandler} />}
            {/* 
            <Paginador onViewChange={viewElementHandler} data={revista.items.sort((a, b) => {
                if (a.id < b.id) {
                    return 1;
                }
                if (a.id > b.id) {
                    return -1;
                }
                return 0;
            })} numberOfElementPerView={12} /> */}

        </div>
    )
};

export default Revista;