import React from 'react';

import classes from './PlaceCard.module.scss';
import { Title300 } from './../../../components/Titles/Titles';
import ImageLoader from '../../../components/ImageLoader/ImageLoader';

const ContainerCard = props => {
    if (props.link) {
        return <a className={classes.Container} href={props.link} target="_blank" rel="noopener noreferrer">
            {props.children}
        </a>
    } else {
        return <div className={classes.Container}>
            {props.children}
        </div>
    }
}

const PlaceCard = props => {
    return (
        <ContainerCard link={props.link}>
            <div className={classes.PlaceCard}>
                <ImageLoader className={classes.Image} imagenPrincipal={props.imagenPrincipal} />
                <div className={classes.Title}>
                    <Title300 style={{ color: props.color }}>{props.title}</Title300>
                </div>
            </div>
        </ContainerCard>
    )
};

export default PlaceCard;