import React, { useEffect, useMemo, useRef, useState } from 'react';
import Icon from '@material-ui/core/Icon';

import classes from './Places.module.scss';
import Place from './Place/Place';
import { BASE_URL } from './../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import * as mainActions from './../../../../store/actions/main';
import useViewportSize from './../../../../hooks/useViewportSize/useViewportSize';
import HorizontalMoveHandler from './../../../../components/HorizontalMoveHandler/HorizontalMoveHandler';
import { setViewportSize } from '../../../../store/actions/globalStyles';


const Places = props => {
    const containerRef = useRef();
    const [actualPosition, setActualPosition] = useState(0);
    const [itemsPerView, setItemsPerView] = useState(1);
    const [elementos, setElementos] = useState(0);
    const { placesList } = props;
    const [arrowStatus, setArrowStatus] = useState(true);

    // let { innerWidth } = window;
    // const [clientWidth, setClientWidth] = useState(innerWidth);


    const viewportSize = useViewportSize();





    useEffect(() => {
        setElementos(placesList.length);
    }, [placesList])



    const [viewPosition, setViewPosition] = useState({
        transform: `translateX(0%)`,
        width: `${(100 / itemsPerView) * elementos}%`
    })

    useEffect(() => {
        const movement = 100 / itemsPerView;
        setViewPosition({
            transform: `translateX(-${movement * actualPosition}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        })
    }, [itemsPerView, elementos, actualPosition])

    const animationTransitionHandler = () => {
        if (containerRef && containerRef.current) {
            containerRef.current.style.transition = null
            containerRef.current.ontransitionend = function (event) {
            }
            containerRef.current.style.transition = 'all .5s';
        }
    }

    const calculateViewerPosition = () => {
        const movement = 100 / elementos;
        animationTransitionHandler();
        setActualPosition(0)
        setViewPosition({
            transform: `translateX(-${movement * actualPosition}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }

    useMemo(() => {

        if (elementos) {
            switch (viewportSize) {
                case 'xs':
                    setItemsPerView(1);
                    break;
                case 'sm':
                    if (elementos >= 2) {
                        setItemsPerView(2);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'md':
                    if (elementos >= 3) {
                        setItemsPerView(3);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'lg':
                    if (elementos >= 4) {
                        setItemsPerView(4);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                case 'xl':
                    if (elementos >= 5) {
                        setItemsPerView(5);
                    } else {
                        setItemsPerView(elementos);
                    }
                    break;
                default:
            }
        }

        calculateViewerPosition();
    }, [elementos, viewportSize])

    useEffect(() => {
        if (itemsPerView === elementos) {
            setArrowStatus(false);
        } else {
            setArrowStatus(true);
        }
    }, [itemsPerView, elementos])


    const nextView = () => {
        if ((elementos / itemsPerView) > (actualPosition + 1)) {
            setActualPosition(actualPosition + 1);
        } else {
            setActualPosition(0);
        }

    }

    const prevView = () => {
        if (actualPosition === 0 && itemsPerView < elementos) {
            setActualPosition(elementos - 1);
        } else {
            setActualPosition(actualPosition - 1)
        }
    }

    useEffect(() => {
        animationTransitionHandler();
        const movement = 100 / elementos;
        setViewPosition({
            transform: `translateX(-${movement * (actualPosition)}%)`,
            width: `${(100 / itemsPerView) * elementos}%`
        });
    }, [actualPosition, itemsPerView, elementos])



    return (
        <div className={classes.Places}>
            <Icon className={[classes.Arrow, !arrowStatus ? classes.Disabled : null].join(' ')} onClick={prevView}>keyboard_arrow_left</Icon>
            <HorizontalMoveHandler onLeft={nextView} onRight={prevView}>
                <div className={classes.Container}>
                    <div ref={containerRef} className={classes.PlacesContainer} style={viewPosition}>
                        {placesList.map(place => <Place link={place.Link} itemsPerView={itemsPerView} key={place.id} placeId={place.id} color={place.color} title={place.titulo} imagen={BASE_URL + place.imagenPrincipal.url} />)}
                    </div>
                </div>
            </HorizontalMoveHandler>
            <Icon className={[classes.Arrow, !arrowStatus ? classes.Disabled : null].join(' ')} onClick={nextView}>keyboard_arrow_right</Icon>
        </div>
    )
};

export default Places;