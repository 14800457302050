export const SET_VIDEO = 'SET_VIDEO';
export const ABRIR_MODAL = 'ABRIR_MODAL';
export const CERRAR_MODAL = 'CERRAR_MODAL';


export const abrirModal = () => {
    return { type: ABRIR_MODAL }
}

export const cerrarModal = () => {
    return { type: CERRAR_MODAL }
}

export const setVideo = (videoUrl) => {
    return { type: SET_VIDEO, videoUrl }
}