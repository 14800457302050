import React from 'react';
import { NavLink } from 'react-router-dom';

import classes from './PlaceLink.module.scss';
import { Paragraph100, Paragraph350 } from './../../../components/Paragraph/Paragraph'

const PlaceLink = props => {
    return (
        <NavLink to={`/turismo/${props.placeId}`} className={classes.PlaceLink} style={{ backgroundColor: props.color }} categoria="turismo" etiqueta={props.label}>
            <Paragraph100>{props.label}</Paragraph100>
        </NavLink>

    )
};

export default PlaceLink;