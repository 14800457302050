import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import Carousel from './../../components/Carousel/Carousel';
import LinkGob from './LinksGob/LinksGob';
import ListaLinkDeInteres from './ListaLinkDeInteres/ListaLinkDeInteres';
import ConocerMas from './ConocerMas/ConocerMas';
import Revista from './Revista/Revista';

import NewsList from './NewsList/NewsList';

import classes from './Main.module.scss';
import RedesSociales from '../../components/RedesSociales/RedesSociales';

import * as principalActions from './../../store/actions/prueba';
import { useScroll } from '../../hooks/useScroll/useScroll';

const Main = props => {

    // const [style, setStyle] = useState({})


    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(principalActions.fetch_data());
        dispatch(principalActions.fetchTexts());
    }, [dispatch]);

    window.setTituloSitio(`Municipalidad de Cartagena`);

    const element = useRef();
    const scroll = useScroll();

    let style = { transition: 'all .5s' }


    if (element.current && element.current.offsetTop - 92 < scroll.scrollY) {
        style = { ...style, position: 'fixed', top: '90px', left: '0' }
    } else {
        style = { transition: 'all .5s' }
    }

    return (
        <div>
            <Carousel></Carousel>
            <div ref={element} className={classes.MainContainer}>
                <div style={style}>
                    <RedesSociales />
                </div>
                <LinkGob></LinkGob>
                <ListaLinkDeInteres></ListaLinkDeInteres>
                <NewsList></NewsList>
                <Revista></Revista>
                <ConocerMas></ConocerMas>
            </div>
        </div>
    )
};

export default Main;