import React, { useState } from 'react';

import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";

import classes from './NotSoImportantNews.module.scss'
import bottom from './../../assets/images/SVG/wave.svg'
import left from './../../assets/images/1x/Asset 2.png'

import { T16, } from './../Titles/Titles';
import { P14, } from './../Paragraph/Paragraph';
import { Icon } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { HOST_URL } from '../../constants';

const NotSoImportantNews = props => {
    const [shareOptions, setShareOptions] = useState(false);
    const { idNoticia, imagen, titular, cuerpo, fechaCreacion, categoria } = props;

    const getTextoResumido = texto => {
        if (texto === undefined) return "";
        const largoMaximo = 190;
        if (texto.length <= largoMaximo) {
            return texto;
        }
        else {
            let nuevoTexto = texto.substring(0, largoMaximo);
            nuevoTexto = nuevoTexto.substring(0, nuevoTexto.lastIndexOf(" "));
            nuevoTexto = `${nuevoTexto}...`
            return nuevoTexto;
        }
    }

    const getFechaFormateada = fechaString => {
        let fecha = new Date(fechaString);
        let fechaFormateada = fecha.getDate() < 10 ? `0${fecha.getDate()}/` : `${fecha.getDate()}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getMonth() < 9 ? `0${fecha.getMonth() + 1}` : fecha.getMonth() + 1}/`;
        fechaFormateada = `${fechaFormateada}${fecha.getFullYear()} - `;
        fechaFormateada = `${fechaFormateada}${fecha.getHours() < 10 ? `0${fecha.getHours()}` : fecha.getHours()}:`;
        fechaFormateada = `${fechaFormateada}${fecha.getMinutes() < 10 ? `0${fecha.getMinutes()}` : fecha.getMinutes()}h`;
        return fechaFormateada;
    }

    const styleImage = {
        backgroundImage: `url(${imagen})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
    }

    const styleLinkNews = {
        textDecoration: "none",
        color: "black",
        // height: "100%",
        display: 'flex',
        /* height: 100%; */
        alignContent: 'space-between',
        /* align-items: stretch; */
        justifyContent: 'center',
        flexDirection: 'column',
    }
    const classList = [classes.News]
    let image = bottom;
    if (props.type) {
        switch (props.type) {
            case "Horizontal":
                classList.push(classes.Horizontal);
                image = left;
                break;
            case "Vertical":
                classList.push(classes.Vertical);
                break;
            case "Small":
                classList.push(classes.Small);
                break;
            default:
                classList.push(classes.Default);
        }

    } else {
        classList.push(classes.Default);
    }

    let estiloFecha = {
        marginLeft: "-10px",
        color: "#b7b7b7",
        fontSize: "1.4em"
    }

    const shareOptionsHandler = () => {
        setShareOptions(!shareOptions)
    }

    return (
        <div className={classList.join(' ')} style={{ marginRight: '10px' }}>

            <div className={classes.Share}>
                <Icon className={classes.ShareIcon} onClick={shareOptionsHandler}>share</Icon>
            </div>
            <div className={[classes.ShareOptions, shareOptions ? classes.Open : null].join(' ')}>
                <WhatsappShareButton className={classes.ShareItemOption} url={`${HOST_URL}/noticia?id=${idNoticia}`} title={titular} ><WhatsappIcon size={34} round={true}></WhatsappIcon ></WhatsappShareButton>
                <FacebookShareButton className={classes.ShareItemOption} url={`${HOST_URL}/noticia?id=${idNoticia}`} quote={titular} hashtag="#hashtag" ><FacebookIcon size={34} round={true}></FacebookIcon></FacebookShareButton>
                <TwitterShareButton className={classes.ShareItemOption} url={`${HOST_URL}/noticia?id=${idNoticia}`} title={titular} ><TwitterIcon size={34} round={true}></TwitterIcon></TwitterShareButton>
                <EmailShareButton className={classes.ShareItemOption} url={`${HOST_URL}/noticia?id=${idNoticia}`} body={titular} subject={titular}><EmailIcon size={34} round={true}></EmailIcon></EmailShareButton>
            </div>

            <Link
                style={styleLinkNews}
                to={`/noticia?id=${idNoticia}`}
                categoria="ultimas-noticias"
                etiqueta={titular}
                accion="click"
            >
                <div className={classes.Image}>
                    <div
                        className={classes.ImageSrc}
                        style={styleImage}
                    />
                    <div className={classes.Bottom} style={{ 'background-image': `url(${image})` }}></div>
                </div>
                <div className={classes.Text}
                //  style={{ height: "57%" }}
                >
                    <div>
                        <T16 left>{titular}</T16>
                        <P14 left>
                            <ReactMarkdown>
                                {getTextoResumido(cuerpo)}
                            </ReactMarkdown>
                        </P14>
                    </div>
                    <div className={classes.Actions} style={estiloFecha}>
                        <small style={{ fontStyle: "italic" }}>
                            {getFechaFormateada(fechaCreacion)}
                        </small>
                        <div>{categoria}</div>
                    </div>
                </div>
            </Link>

        </div>
    )
};

export default NotSoImportantNews;