import React from 'react';

import classes from './LinkPrincipal.module.scss';
import { Icon } from '@material-ui/core';

import { Paragraph200, Paragraph300 } from './../../../../components/Paragraph/Paragraph';

const LinkPrincipal = props => {
    return (
        <a 
            className={classes.LinkPrincipal}
            href={props.redirect}
            target="_blank"
            rel="noopener noreferrer"
            categoria="link-informativos"
            etiqueta={props.title}>
                
            <Icon className={classes.Icon} >{props.icon}</Icon>
            <div className={classes.Label}>
                <Paragraph200>{props.title}</Paragraph200>
                <Paragraph300>{props.label}</Paragraph300>
            </div>
        </a>
    )
};

export default LinkPrincipal;