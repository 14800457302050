import Axios from './../../axios/strapi';

export const FETCH_FOOTER_MINISTERIOS = "FETCH_FOOTER_MINISTERIOS";
export const FETCH_FOOTER_CONTACTOS = "FETCH_FOOTER_CONTACTOS";
export const FETCH_REDES_SOCIALES = "FETCH_REDES_SOCIALES";
export const FETCH_DIRECCION = "FETCH_DIRECCION";

export const fetchFooterMinisterios = () => {
    return async dispatch => {
        try {
            const ministerios = await Axios.get('/footer-ministerios', {
                params: {
                    estado: true,
                    _sort: "orden:asc",
                }
            })


            dispatch({ type: FETCH_FOOTER_MINISTERIOS, ministerios: ministerios.data })

        } catch (err) {

        }
    }
}

export const fetchFooterContactos = () => {
    return async dispatch => {
        try {
            const contactos = await Axios.get('/footer-contactos', {
                params: {
                    estado: true,
                    _sort: "orden:asc",
                }
            })

            dispatch({ type: FETCH_FOOTER_CONTACTOS, contactos: contactos.data })

        } catch (err) {

        }
    }
}

export const fetchRedSocial = () => {
    return async dispatch => {
        try {
            const social = await Axios.get('/footer-redes-sociales', {
                params: {
                    estado: true,
                    _sort: "orden:asc",
                }
            })

            dispatch({ type: FETCH_REDES_SOCIALES, social: social.data })

        } catch (err) {

        }
    }
}

export const fetchDireccion = () => {
    return async dispatch => {
        try {
            const direccion = await Axios.get('informacion-general')

            dispatch({ type: FETCH_DIRECCION, direccion: direccion.data })

        } catch (err) {

        }
    }
}


