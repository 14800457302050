import React from 'react';
import { NavLink } from 'react-router-dom';
import ImageLoader from '../../../../../components/ImageLoader/ImageLoader';
import { Title100 } from '../../../../../components/Titles/Titles';

import classes from './Place.module.scss';
import './Swipe.scss';



const Place = props => {

    const { link } = props;

    let style = {};
    if (props.color) {
        style = { background: props.color }
    }

    const classList = [classes.PlaceItem];

    classList.push(`Swipper-${props.itemsPerView}`)

    const fixToExternalLink = link.substring(link.indexOf("/"));
    const isInternal = link === fixToExternalLink;

    return (
        <NavLink to={fixToExternalLink} className={classList.join(' ')} categoria="turismo" etiqueta={props.title} target={isInternal ? "_self" : "_blank"}>
            {/* <div > */}
            {/* <div className={classes.Image} style={{ background: `url('${props.imagen}')`, backgroundPosition: 'center', backgroundSize: 'cover' }} /> */}
            
            <ImageLoader className={classes.Image} imagenPrincipal={props.imagen} />
            <div className={classes.PlaceTitle} style={style}>
                <Title100 style={{ textTransform: 'uppercase' }}>
                    {props.title}
                </Title100>
            </div>
            {/* </div> */}
        </NavLink >
    )
};

export default Place;