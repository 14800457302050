import { Icon } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import classes from './PaginadorElementos.module.scss';

const PaginadorElementos = props => {

    const [pageListNumber, setPageListNumber] = useState([]);

    const { totalPaginas, pagePosition, pagePositionHandler } = props


    const nextPage = () => {
        if (pagePosition < totalPaginas) {
            pagePositionHandler(pagePosition + 1);
        }
    }

    const prevPage = () => {
        if (pagePosition > 1) {
            pagePositionHandler(pagePosition - 1);
        }
    }

    const paginatorNumberClickHandler = (number) => {
        pagePositionHandler(number);
    }

    useEffect(() => {

        let fin = Math.ceil(totalPaginas) - 3;

        if (Math.ceil(totalPaginas) > 4) {
            let mediumNumbers = Array.from(Array(3), (x, k) => {
                if (pagePosition < 3) {
                    return k + 1
                }
                if (pagePosition >= 3 && pagePosition < fin) {
                    return (pagePosition - 1) + k
                } else {
                    return (fin - 1) + k

                }


            })



            if (mediumNumbers[0] > 1) {
                mediumNumbers = ['...', ...mediumNumbers]
            }

            if (mediumNumbers[mediumNumbers.length - 1] + 1 < totalPaginas - 1) {
                mediumNumbers = [...mediumNumbers, '...']
            }
            setPageListNumber([1, ...mediumNumbers, totalPaginas - 1]);

        } else {
            let number = Array.from(Array(totalPaginas), (x, k) => k + 1)
            setPageListNumber(number);
        }


    }, [props.data, pagePosition, setPageListNumber])






    return (
        <div className={classes.PaginadorContainer}>
            <div className={classes.Paginador}>
                <Icon className={classes.Arrow} onClick={prevPage}>keyboard_arrow_left</Icon>
                <div>
                    {pageListNumber.map((el, i) => {
                        if (typeof el === 'string') {
                            return (
                                <span key={i} >{el}</span>
                            )
                        } else {
                            return (
                                <span
                                    key={i}
                                    className={[classes.PaginatorCountItem, el === pagePosition ? classes.Selected : null].join(' ')}
                                    onClick={() => paginatorNumberClickHandler(el)}
                                >{el}</span>
                            )

                        }
                    })}
                </div>


                <Icon className={classes.Arrow} onClick={nextPage}>keyboard_arrow_right</Icon>
            </div>
        </div>
    )
};

export default PaginadorElementos;