import { Icon } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Title400 } from '../../../Titles/Titles';
import LinkMenu from '../LinkMenu/LinkMenu';

import classes from './LinkMenuGroup.module.scss';

const LinkMenuGroup = props => {
    const [submenuStatus, setSubMenuStatus] = useState(false);

    const [containerClasses, setContainerClasses] = useState([classes.ListMenu])

    const list = useRef();

    const menuStatusHandler = () => {
        setSubMenuStatus(!submenuStatus);
        if (!submenuStatus) {
            setTimeout(() => {
                setContainerClasses([classes.ListMenu, classes.Visible])
            }, 10)

        } else {
            setContainerClasses([classes.ListMenu, classes.Hide])
        }
    }


    return (
        <li className={classes.LinkMenuGroup}>
            <div className={classes.Title} onClick={menuStatusHandler}>
                <Title400>{props.label}</Title400><Icon>{!submenuStatus ? 'keyboard_arrow_down' : 'keyboard_arrow_up'}</Icon>
            </div>
            <ul ref={list} className={containerClasses.join(' ')}  >


                {
                    submenuStatus ? props.link.sort((a, b) => {
                        if (a.orden > b.orden) {
                            return 1;
                        }
                        if (a.orden < b.orden) {
                            return -1;
                        }
                        return 0;
                    })
                        .filter(a => a.status)
                        .map(childLink => {
                            if (typeof childLink.link === 'string') {
                                return <LinkMenu label={childLink.label} link={childLink.link} closeHandler={props.closeHandler} />
                            } else {
                                return <LinkMenuGroup label={childLink.label} link={childLink.link} closeHandler={props.closeHandler} />
                            }
                        }) : null
                }

            </ul>
        </li>
    )
};

export default LinkMenuGroup;