import { CREAR_PAGINADOR, FETCH_BUSCAR_NOTICIAS, FETCH_MAS_VISTAS, FETCH_NOTICIAS_ANTERIORES, FETCH_ULTIMAS_NOTICIAS, IR_PAGINA, LIMPIAR_BUSCADOR } from "../actions/noticias";

const initState = {
    masVistas: null,
    ultimasNoticias: null,
    noticiasAnteriores: null,
    paginador: null,
    buscador: []
}

export default (state = initState, action) => {
    switch (action.type) {
        case FETCH_MAS_VISTAS:
            return {
                ...state,
                masVistas: action.masVistas
            }
        case FETCH_ULTIMAS_NOTICIAS:
            return {
                ...state,
                ultimasNoticias: action.ultimasNoticias
            }
        case FETCH_NOTICIAS_ANTERIORES:
            return {
                ...state,
                noticiasAnteriores: action.noticiasAnteriores
            }
        case CREAR_PAGINADOR:
            return {
                ...state,
                paginador: action.paginador
            }
        case IR_PAGINA: {
            return {
                ...state,
                paginador: {
                    ...state.paginador,
                    paginaActual: action.pagina
                }
            }
        }
        default:
            return state;
    }
}