import { AGRANDAR_FUENTE, DESTACAR_LINK, REDUCIR_FUENTE, SET_VIEWPORT_SIZE } from '../actions/globalStyles';

const initState = {
    fontSize: 6,
    viewportSize: 'xs',
    linkStatus: false
}


export default (state = initState, action) => {
    switch (action.type) {
        case AGRANDAR_FUENTE:

            if (state.fontSize < 9) {
                return {
                    ...state,
                    fontSize: state.fontSize + 1
                }
            } else {
                return state;
            }
        case REDUCIR_FUENTE:
            if (state.fontSize > 6) {
                return {
                    ...state,
                    fontSize: state.fontSize - 1
                }
            } else {
                return state;
            }
        case SET_VIEWPORT_SIZE:
            return {
                ...state,
                viewportSize: action.viewportSize
            }
        case DESTACAR_LINK:
            return {
                ...state,
                linkStatus: action.flag
            }
        default:
            return state;
    }
}