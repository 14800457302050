import Axios from "./../../axios/strapi";

export const FETCH_LINK_INTERES = 'FETCH_LINK_INTERES';

export const fetchLinkInteres = () => {
    return async (dispatch) => {

        try {
            const response = await Axios.get('link-interes');

            dispatch({
                type: FETCH_LINK_INTERES,
                links: response.data
            })
        } catch (err) {
            throw err;
        }

    }
}
