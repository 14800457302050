import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import classes from './ConsejoMunicipal.module.scss';
import * as consejoMunicipalActions from './../../store/actions/consejoMunicipal';
import RichTextReader from '../../components/RichTextReader/RichTextReader';
import { TitlePrincipal } from '../../components/Titles/Titles';
import Loader from '../../components/Loader/Loader';
import UserPresentation from '../../components/UserPresentation/UserPresentation';
import { BASE_URL } from '../../constants';

const ConsejoMunicipal = props => {


    window.scroll({
        top: 0,
        behavior: 'smooth'
    })


    const consejeros = useSelector(state => state.consejoMunicipal.consejeros);
    const presentacion = useSelector(state => state.consejoMunicipal.presentacion);
    const titulo = useSelector(state => state.consejoMunicipal.titulo);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(consejoMunicipalActions.fetch_consejo())
    }, [dispatch])

    return (
        <div className={classes.ConsejoMunicipal}>
            <TitlePrincipal>
                {titulo}
            </TitlePrincipal>
            {presentacion ? <RichTextReader bodyText={presentacion} /> : <Loader />}

            <section className={classes.Consejeros}>
                {
                    consejeros ?
                        consejeros.map(c => <UserPresentation
                            cargo={c.Cargo}
                            nombre={c.Nombre}
                            imagen={BASE_URL + c.imagen.url}
                        />) : null
                }
            </section>
        </div>
    )
};

export default ConsejoMunicipal;